// eslint-disable-next-line import/no-extraneous-dependencies
import Timecode from 'smpte-timecode';
import VuetifyConfirm from 'vuetify-confirm';
// eslint-disable-next-line import/no-extraneous-dependencies
import axios from 'axios';
import VueAxios from 'vue-axios';
import Router from 'vue-router';
import VuetifyDialog from 'vuetify-dialog';
import 'vuetify-dialog/dist/vuetify-dialog.css';

import App from './App.vue';
// eslint-disable-next-line import/no-cycle
import router from './router';
// eslint-disable-next-line import/no-cycle
import store from './store';
import vuetify from './plugins/vuetify';

// eslint-disable-next-line import/no-extraneous-dependencies
import 'intersection-observer';


// eslint-disable-next-line import/order
import TextHighlight from 'vue-text-highlight';
// eslint-disable-next-line import/order
import Vue from 'vue';
import { BASE_URL, API_URL } from './api';

Vue.component('text-highlight', TextHighlight);
Vue.use(VuetifyDialog, {
  context: {
    vuetify,
  },
});
Vue.use(VueAxios, axios);

Vue.config.productionTip = false;
Vue.prototype.$base_url = BASE_URL;
Vue.prototype.$api_url = API_URL;


Vue.use(VuetifyConfirm, {
  vuetify,
  buttonTrueText: 'Accepter',
  buttonFalseText: 'Annuler',
  color: 'warning',
  icon: 'mdi-warning',
  title: 'Attention',
  width: 350,
  property: '$confirm',
});
Vue.use(require('vue-shortkey'));

Vue.filter('timecode', (value) => {
  if (!value) return '';
  return new Timecode(parseFloat(value) * 25, 25);
});

Vue.use(Router);
Vue.prototype.$filters = Vue.options.filters;
const token = localStorage.getItem('token');
if (token) {
  Vue.axios.defaults.headers.common.Authorization = `Bearer ${token}`;
}

const app = new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app');


export default app;
