<template>
<v-container fluid="">
    <v-dialog v-model="dialog" width="80%">

        <v-card v-if="dialog===true">
            <v-card-title>
                <v-btn icon @click="dialog=false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>{{current_clip.name}}
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col>
                        <hls-player :source="hls"></hls-player>
                    </v-col>
                    <v-col>
                        <v-list-item two-line>
                            <v-list-item-content>
                                <v-list-item-title>Pays</v-list-item-title>
                                <v-list-item-subtitle>{{current_clip.pays}}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item two-line>
                            <v-list-item-content>
                                <v-list-item-title>Ville</v-list-item-title>
                                <v-list-item-subtitle>{{current_clip.ville}}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item two-line>
                            <v-list-item-content>
                                <v-list-item-title>Commentaire</v-list-item-title>
                                <v-list-item-subtitle>{{current_clip.comment}}
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item two-line>
                            <v-list-item-content>
                                <v-list-item-title>Tags</v-list-item-title>
                                <v-list-item-subtitle>
                                  <span v-for="t in current_clip.tags"
                                  :key="t.id">{{t.text}}</span></v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        <v-btn>Voir la carte</v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>

    <v-col cols="12">
        <v-card :loading="loading">
            <v-card-title>Sélections

            </v-card-title>
            <v-card-text>
                <v-text-field v-model="search"
                append-icon="mdi-magnify" label="Checher"
                ingle-line hide-details></v-text-field>
                <v-data-table :search="search"
                :headers="headers" :items="selections"
                :single-expand="true" :expanded.sync="expanded" show-expand="">
                   <template v-slot:item.name="{ item }">
                 <text-highlight :queries="[search]">{{item.name}}</text-highlight>
             </template>
                    <template v-slot:item.actions="{ item }">
                      <v-tooltip bottom>
       <template v-slot:activator="{ on }">
                          <v-btn icon v-on="on" @click="download_arch(item)" >
                                        <v-icon>mdi-download</v-icon>
                                    </v-btn></template><span>Téléchager MamArch</span></v-tooltip>
                        <v-tooltip bottom>
      <template v-slot:activator="{ on }">
                        <v-btn  color="red" v-on="on" @click="delete_sel(item)" icon>
                            <v-icon>mdi-delete</v-icon>
                        </v-btn></template><span>Supprimer</span>
    </v-tooltip>


                         <v-tooltip bottom>
      <template v-slot:activator="{ on }">
                        <v-btn  color="primary" v-on="on" @click="edit_sel(item)" icon>
                            <v-icon>mdi-pencil</v-icon>
                        </v-btn></template><span>Edit</span>
    </v-tooltip>

<v-tooltip bottom>
       <template v-slot:activator="{ on }">
                          <v-btn  color="green" v-on="on" @click="share(item)" icon>
                            <v-icon>mdi-share</v-icon>
                        </v-btn></template><span>Partager</span></v-tooltip>
                    </template>

                    <template v-slot:item.owned="{ item }">
                        <v-chip v-if="item.owned" color="green">Oui</v-chip>
                        <v-chip v-else color="red">Oui</v-chip>
                    </template>

                    <template v-slot:item.users="{ item }">

                        <v-select v-model="item.users"
                        :items="users" label="Utilisateurs partagés"
                        multiple chips @change="change_user(item)"></v-select>
                    </template>
                    <template v-slot:expanded-item="{ headers }">
                        <td :colspan="headers.length">
                            <v-row v-if="expanded[0]">
                                <v-col cols="3" style="cursor:pointer;"
                                v-for="c in expanded[0].clips" :key="c.id"
                                class="ma-2 pa-2 white" :class="{ 'success': current_clip===c }">
                                    <v-img @click="current_clip = c"
                                    :src="$api_url+'/asset_th/'+c.id"></v-img>
                                    <v-btn @click="delete_clip(c, expanded[0].id)" icon color="red">
                                        <v-icon>mdi-delete</v-icon>
                                    </v-btn>

                                    <v-spacer></v-spacer>
                                </v-col>
                            </v-row>

                        </td>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
    </v-col>

</v-container>
</template>

<script>
import HlsPlayer from '../components/player/HlsPlayer.vue';

export default {
  name: 'SelectionComponent',
  components: {
    HlsPlayer,
  },
  computed: {
    hls() {
      return `${this.$base_url}/hls/asset/${this.current_clip.card}/,${this.current_clip.name}.mp4.urlset/master.m3u8`;
    },
  },
  methods: {
    async edit_sel(sel) {
      const person = await this.$dialog.prompt({
        text: 'Renommer',
        title: 'Nom de la sélection',
        value: sel.name,
      });
      if (person !== false) {
        if (person !== null && person !== '') {
          this.$http.post(`${this.$api_url}/rename_sel`, { sel_id: sel.id, sel_name: person }).then(() => {
            this.$set(this.selections[this.selections.indexOf(sel)], 'name', person);
          });
        }
      }
    },
    change_user(sel) {
      this.$http.post(`${this.$api_url}/change_sel_owner`, { sel_id: sel.id, sel_users: sel.users });
    },
    download_arch(sel) {
      this.$http({
        url: `${this.$api_url}/arch/${sel.id}`,
        method: 'GET',
        responseType: 'blob', // important
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${sel.name}.mamarch`);
        document.body.appendChild(link);
        link.click();
      });
    },
    async share(sel) {
      await this.$dialog.confirm({
        text: `<b>URL :</b> ${this.$base_url}/#/shareSel/${sel.uuid}<br><b>MOT DE PASSE : </b>${sel.passw}`,
        title: `Partager : ${sel.name}`,
      });
    },
    delete_sel(sel) {
      this.$confirm('Voulez-vous supprimer la sélection ?').then((res) => {
        if (res === true) {
          this.$http.post(`${this.$api_url}/del_sel`, { id: sel.id }).then(
            () => {
              this.$delete(this.selections, this.selections.indexOf(sel));
            },
          );
        }
      });
    },
    delete_clip(clip, sel) {
      this.$confirm('Voulez-vous vraiment supprimer le clip ?').then((res) => {
        if (res === true) {
          this.$http.post(`${this.$api_url}/remove_clip_from_sel`, {
            sel,
            clip: clip.id,
          }).then(
            () => {
              this.$delete(this.expanded[0].clips, this.expanded[0].clips.indexOf(clip));
            },
          );
        }
      });
    },
  },

  watch: {

    dialog(newvalue) {
      if (newvalue === true) {
        this.$router.push('/selections/play');
      } else {
        this.$router.push('/selections');
      }
    },
    current_clip(newvalue) {
      this.dialog = (newvalue !== null);
    },

    expanded() {
      this.current_clip = null;
    },
  },
  mounted() {
    this.$http.get(`${this.$api_url}/selections`).then((res) => {
      this.selections = res.data.selections;
      this.users = res.data.users;
      this.loading = false;
    });
  },
  data() {
    return {
      headers: [{
        text: 'id',
        value: 'id',
        filterable: false,
      },
      {
        text: 'Nom',
        value: 'name',

      },
      {
        text: 'Propriétaire',
        value: 'owned',
        filterable: false,
      },
      {
        text: 'Utilisateurs',
        value: 'users',
        filterable: false,
      },
      {
        text: 'Actions',
        value: 'actions',
        filterable: false,
      },
      ],
      loading: true,
      selections: [],
      users: [],
      expanded: [],
      current_clip: null,
      dialog: false,
      search: '',
    };
  },
};
</script>

<style>
.active {
    color: brown;
}
</style>
