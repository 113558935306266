<template>

 <v-tooltip top>
      <template v-slot:activator="{ on }">
        <v-icon class="comment" @click="select"
:style="{left:left}"  v-on="on" :color="color">mdi-comment-account</v-icon>
      </template>
      <span>{{comment.comments[0].msg}}</span>
    </v-tooltip>

</template>

<script>
export default {
  name: 'UserCommentObject',
  props: {
    comment: {
      type: Object,
      default() {
        return {};
      },
    },
    factor: {
      type: Number,
      default: 1,
    },
    selected: Boolean,
  },
  methods: {
    select() {
      this.$emit('commentSelected', this.comment);
    },
  },
  computed: {
    color() {
      if (this.selected === true) {
        return 'warning';
      }
      return 'primary';
    },
    left() {
      const a = this.comment.time * this.factor;
      return `${a}px`;
    },
  },
};
</script>

<style lang="scss" scoped>
.comment {
    position: absolute;
}
</style>
