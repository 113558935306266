<template>
<v-flex xs12>
 <v-dialog v-model="dialog">
    <clip-edit-dialog @cancel='closeDialog()' v-if="dialog"
 :canNext="canNext"
  :canPrevious=canPrevious
    :currentClip.sync=currentClip
    @previous="previous"
    :selections=selections
    @next="next"></clip-edit-dialog>
  </v-dialog>
<v-card :loading="loading || search_loading" v-show="!searched">
  <v-card-title>Recherche de clips</v-card-title>
  <v-card-text>
    <v-text-field
    v-model="term"
    hint="Pour rechecher un clip par id tapez id:NUMERO ex. id:123"
    @keydown.enter.prevent="search">
    </v-text-field>
    <v-autocomplete
    clearable=""
    label="Intelligence artificielle"
    :items="terms"
    ></v-autocomplete>
    <v-autocomplete
     clearable=""
      label="Pays"
      :items="pays"
      v-model="selected_pays"
    ></v-autocomplete>
     <v-autocomplete
     clearable=""
      label="Ville"
      v-model="selected_ville"
      :items="villes"
    ></v-autocomplete>
    <v-label>Tags...</v-label>
<tags-editor :tags.sync="tags"></tags-editor>
    <v-row>
      <v-col cols="10"><v-spacer></v-spacer></v-col>
      <v-col>
    <v-btn text @click="search">Chercher</v-btn></v-col></v-row>
  </v-card-text>
</v-card>
<v-card v-show="searched">
  <v-card-title><span v-if="term" > Texte de recherche :
     <v-chip color="primary">{{term}}</v-chip></span>
  <span v-if="selected_pays">Pays : <v-chip color="accent">{{selected_pays}}</v-chip></span>
  <span v-if="selected_ville">Ville : <v-chip color="accent">{{selected_ville}}</v-chip></span>
    <span v-if="tags_list">Tags : <v-chip color="info"> {{tags_list}}</v-chip></span>

  <v-spacer></v-spacer>
  <v-btn text @click="searched = false">Modifier...</v-btn></v-card-title>

  <v-card-text>
       <v-row>
  </v-row>
<v-data-table ref="table"
:loading="search_loading"
:headers="headers"
:items="clips"
:page.sync="current_page"
:server-items-length="total"
:footer-props="{
'items-per-page-options': [5,10, 20,50,150,200,250]
}"
:items-per-page.sync="item_per_page"

>
  <template v-slot:item.id="{ item }">

                  <v-avatar v-if="$vuetify.breakpoint.xsOnly">
                  <img :src="$api_url+'/asset_th/'+item.id" ></v-avatar>
                     <img  v-else :src="$api_url+'/asset_th/'+item.id" >
              </template>

              <template v-slot:item.tags="{ item }">
                 <v-chip v-for="t in item.tags" :key="t.id">{{t.text}}</v-chip>
              </template>
                     <template v-slot:item.card_name="{ item }">
                <a href="#" @click.prevent="go_to_card(item.card, item.id)"> {{item.card_name}}</a>
              </template>
              <template v-slot:item.action="{ item }">
                <v-tooltip bottom>
           <template v-slot:activator="{ on }">
             <v-btn icon=""   @click="select_clip(item)" v-on="on">
               <v-icon>mdi-eye</v-icon>
               </v-btn>
           </template>
           <span>Détails</span></v-tooltip>


    </template>
</v-data-table>
  </v-card-text>
</v-card>
</v-flex>
</template>

<script>
import TagsEditor from '../components/clips/TagsEditor.vue';
import ClipEditDialog from '../components/clips/ClipEditDialog.vue';

export default {

  name: 'Search',
  components: {
    TagsEditor, ClipEditDialog,
  },
  data() {
    return {
      selections: [],
      currentClip: undefined,
      dialog: false,
      loading: true,
      searched: false,
      term: '',
      search_loading: false,
      villes: [],
      item_per_page: 10,
      pays: [],
      tags: [],
      terms: [],
      selected_pays: null,
      selected_ville: null,
      current_page: 1,
      total: -1,
      current_clip: [],
      pagination: undefined,
      headers: [{
        text: '',
        align: 'left',
        sortable: false,
        value: 'id',
      },
      {
        text: 'Nom',
        align: 'left',
        sortable: true,
        value: 'name',
      },
      {
        text: 'Carte',
        align: 'left',
        sortable: false,
        value: 'card_name',
      },
      {
        text: 'Description',
        align: 'left',
        sortable: false,
        value: 'comment',
      },
      {
        text: 'Tags',
        align: 'left',
        sortable: false,
        value: 'tags',
      },
      {
        text: 'Pays',
        align: 'left',
        sortable: false,
        value: 'pays',
      }, {
        text: 'Ville',
        align: 'left',
        sortable: false,
        value: 'ville',
      },
      { text: 'Actions', value: 'action', sortable: false },
      ],

      rowsPerPageItems: [5, 10, 20, 50, 100],
      clips: [],
    };
  },
  watch: {
    current_page() {
      this.search();
    },
    item_per_page() {
      this.search();
    },
  },
  methods: {
    previous() {
      const index = this.clips.indexOf(this.currentClip[0]);
      if (index > 0) {
        this.currentClip = [this.clips[index - 1]];
      } else if (this.current_page > 1) {
        this.current_page = this.current_page - 1;
        this.search().then(() => {
          this.currentClip = [this.clips[this.clips.length - 1]];
        });
      }
    },
    next() {
      const index = this.clips.indexOf(this.currentClip[0]);

      if (index < this.clips.length - 1) {
        this.currentClip = [this.clips[index + 1]];
        return;
      }

      this.current_page = this.current_page + 1;
      this.search().then(() => {
        this.currentClip = [this.clips[0]];
      });
    },
    closeDialog() {
      this.dialog = false;
      this.currentClip = [];
    },
    select_clip(clip) {
      this.currentClip = [clip];
      this.dialog = true;
    },
    go_to_card(index, clip) {
      this.$http.get(`${this.$api_url}/group_from_card/${index}`).then((group) => {
        this.$router.push(`/project_details/${group.data.id}?card=${index}&clip=${clip}`);
      });
    },
    async search() {
      this.search_loading = true;
      const res = await this.$http.post(`${this.$api_url}/search`, {
        pays: this.selected_pays,
        page: this.current_page,
        ville: this.selected_ville,
        pagination: this.item_per_page,
        tags: this.tags,
        term: this.term,
      });
      this.total = res.data.count;
      this.clips = res.data.clips;
      this.searched = true;
      this.$router.push({
        path: this.$route.path,
        query: {
          pays: this.selected_pays,
          ville: this.selected_ville,
          page: this.current_page,
          pagination: this.item_per_page,
          term: this.term,
          tags: JSON.stringify(this.tags.map(a => ({ text: a.text, id: a.id }))),
        },
      });
      this.search_loading = false;
    },
  },
  computed: {
    tags_list() {
      return this.tags.map(a => a.text).join(', ');
    },
    canPrevious() {
      if (this.current_page === 1 && this.clips.indexOf(this.currentClip[0]) === 0) {
        return true;
      }
      return false;
    },
    canNext() {
      return false;
    },
  },
  mounted() {
    this.$http.get(`${this.$api_url}/init_search`).then((res) => {
      this.pays = res.data.pays;
      this.villes = res.data.villes;
      this.terms = res.data.terms;

      if (this.$route.query.term !== undefined) {
        this.selected_ville = this.$route.query.ville;
      }
      if (this.$route.query.term !== undefined) {
        this.selected_pays = this.$route.query.pays;
      }
      if (this.$route.query.term !== undefined) {
        this.term = this.$route.query.term;
      }
      if (this.$route.query.tag !== undefined) {
        this.tags = JSON.parse(this.$route.query.tags);
      }
      if (this.$route.query.current_page !== undefined) {
        this.current_page = parseInt(this.$route.query.page, 10);
      }
      if (this.$route.query.item_per_page !== undefined) {
        this.item_per_page = parseInt(this.$route.query.pagination, 10);
      }
      this.loading = false;
      this.$http.get(`${this.$api_url}/selections`).then((res2) => {
        this.selections = res2.data.selections;
        if (this.$route.query.length > 0) {
          this.search();
        }
      });
    });
  },
};
</script>
