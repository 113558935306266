var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',[_c('v-card',[_c('v-card-title',[_vm._v("Logiciels MAM")]),_c('v-card-text',[_c('v-list',[_c('v-list-item',[_c('v-list-item-avatar',[_c('v-icon',[_vm._v("mdi-cloud-upload")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("MamUploader ")]),_c('v-list-item-subtitle',[_vm._v("Cet outil vous permet d'envoyer vos validation et masters depuis n'importequel logiciel de montage. Ou par simple glisser-déposer")])],1),_c('v-list-item-action',[_vm._v(" Télécharger : "),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","href":_vm.$base_url +
                            '/static/apps/uploader/MamUploader.dmg'}},[_c('v-icon',[_vm._v("mdi-apple")])],1),_c('v-btn',{attrs:{"icon":"","href":_vm.$base_url +
                            '/static/apps/uploader/MamUploader-win-latest.zip'}},[_c('v-icon',[_vm._v("mdi-microsoft-windows")])],1),_c('v-btn',{attrs:{"icon":"","href":_vm.$base_url +
                            '/static/apps/uploader/MamUploader-rhl-latest.zip'}},[_c('v-icon',[_vm._v("mdi-linux")])],1)],1)],1),_c('v-list-item',[_c('v-list-item-avatar',[_c('v-icon',[_vm._v("mdi-database")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("MamManager ")]),_c('v-list-item-subtitle',[_vm._v("Gestion et administration du MAM.")])],1),_c('v-list-item-action',[_vm._v(" Télécharger : "),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","href":_vm.$base_url +
                            '/static/apps/manager/MamManager-mac-latest.tar.gz'}},[_c('v-icon',[_vm._v("mdi-apple")])],1),_c('v-btn',{attrs:{"icon":"","href":_vm.$base_url +
                            '/static/apps/manager/MamManager-win-latest.zip'}},[_c('v-icon',[_vm._v("mdi-microsoft-windows")])],1),_c('v-btn',{attrs:{"icon":"","href":_vm.$base_url +
                            '/static/apps/manager/MamManager-rhl-latest.zip'}},[_c('v-icon',[_vm._v("mdi-linux")])],1)],1)],1),_c('v-list-item',[_c('v-list-item-avatar',[_c('v-icon',[_vm._v("mdi-format-list-bulleted")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("EdlTools ")]),_c('v-list-item-subtitle',[_vm._v("Transformation des EDL en XLSX.")])],1),_c('v-list-item-action',[_vm._v(" Télécharger : "),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","href":_vm.$base_url +
                            '/static/apps/edl/EDLTools-mac-latest.tar.gz'}},[_c('v-icon',[_vm._v("mdi-apple")])],1),_c('v-btn',{attrs:{"icon":"","href":_vm.$base_url +
                            '/static/apps/edl/EDLTools-win-latest.zip'}},[_c('v-icon',[_vm._v("mdi-microsoft-windows")])],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }