<template>
<v-card height="100%">
                        <v-card-title> Commentaires </v-card-title>
                        <v-card-text>
                            <div v-if="currentCard.comment">
                            {{currentCard.comment}} </div>
                            <div v-else>
                                Pas de commentaire...
                            </div>
                        </v-card-text>
                        <v-card-actions><v-spacer></v-spacer>
                        <v-btn icon @click="editComment"><v-icon>mdi-pencil</v-icon></v-btn>
                        </v-card-actions>

                    </v-card>
</template>

<script>
export default {
  name: 'CommentEditor',
  props: {
    currentCard: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    async editComment() {
      const res = await this.$dialog.prompt({
        text: 'Commentaire',
        title: 'Votre commentaire',
        value: this.currentCard.comment,
      });

      if (res !== null) {
        this.currentCard.comment = res;
      }
    },
  },

};
</script>

<style>

</style>
