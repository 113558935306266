
<template>
  <div class="text-center">
     <v-tooltip bottom="">
      <template v-slot:activator="{ on }">
        <v-btn
          icon
          dark
          v-on="on"
          @click="load_dialog"
        >
          <v-icon>mdi-folder-network</v-icon>
        </v-btn>
      </template><span>Monter dans mon espace perso</span></v-tooltip>

    <v-dialog
      v-model="dialog"
      width="50%"
    >
      <v-stepper v-model="e1">
        <v-stepper-header>
          <v-stepper-step
            :complete="e1 > 1"
            step="1"
          >
            Choisir les cartes
          </v-stepper-step>
          <v-divider />
          <v-stepper-step
            :complete="e1 > 2"
            step="2"
          >
            Point de montage
          </v-stepper-step>
          <v-divider />
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1">
            Hello
            <v-card
              class="mb-12"
              color="black lighten-1"
              height="200px"
              style="overflow: auto;"
            >
              <v-card-text>
                Cartes à monter : <v-btn
                  text
                  color="success"
                  @click="allCards"
                >
                  Toutes les cartes
                </v-btn>
                <v-checkbox
                  v-for="c in cards"
                  :key="c.id"
                  v-model="selectedCards"
                  :label="c.name"
                  :value="c.id"
                  :disabled="c.on_raid === false"
                />
              </v-card-text>
            </v-card>

            <v-btn
              :disabled="selectedCards.length === 0"
              color="primary"
              @click="e1 = 2"
            >
              Continue
            </v-btn>

            <v-btn
              @click="reinit"
              text
            >
              Cancel
            </v-btn>
          </v-stepper-content>

          <v-stepper-content step="2">
            <v-card
              :loading="loading"
              class="mb-12"

              height="300px"
            >
              <v-card-text>
                <v-radio-group
                  v-model="root"
                  :mandatory="false"
                >
                  <v-radio
                    label="A la racine"
                    :value="true"
                  />
                  <v-radio
                    label="Dans un projet"
                    :value="false"
                  />
                </v-radio-group>
                <v-select
                  :disabled="root===true"
                  :items="projects"
                  label="Projet"
                  v-model="where"
                />
                <v-btn
                  @click="addProject"
                  :disabled="root===true"
                  color="success"
                  text
                >
                  Ajouter un projet
                </v-btn>
              </v-card-text>
            </v-card>

            <v-btn
              color="primary"
              @click="mount"
            >
              Continue
            </v-btn>

            <v-btn
              @click="reinit"
              text
            >
              Cancel
            </v-btn>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-dialog>
  </div>
</template>


<script>
export default {
  name: 'MountComponent',
  props: {
    cards: {
      type: Array,
      default: () => [],
    },
    currentCard: {
      type: Object,
      default: null,
    },
  },

  methods: {
    mount() {
      this.$http.post(`${this.$api_url}/mount`, { cards: this.selectedCards, root: this.root, where: this.where }).then((e) => {
        if (e.data.result === true) {
          this.$dialog.message.info('Opération réalisée avec succès', {
            position: 'top-left',

          });
          this.reinit();
          return;
        }
        this.$dialog.message.error(`Une erreur s'est produite : ${e.data.data}`, {
          position: 'top-left',

        });
      });
    },
    async addProject() {
      const res = await this.$dialog.prompt({
        text: 'Ajouter un projet',
        title: 'Nom du projet',

      });
      if (res === false || res === undefined) {
        return;
      }
      if (this.projects.includes(res)) {
        this.where = res;
        return;
      }
      this.loading = true;
      this.$http.post(`${this.$api_url}/new_mount`, { name: res }).then(() => {
        this.projects.push(res);
        this.where = res;
        this.loading = false;
      });
    },
    allCards() {
      this.cards.forEach((c) => {
        if (c.on_raid === true) {
          this.selectedCards.push(c.id);
        }
      });
    },
    load_dialog() {
      if (this.currentCard.on_raid === true) {
        this.selectedCards = [this.currentCard.id];
      } else {
        this.selectedCards = [];
      }
      this.dialog = true;
    },
    reinit() {
      this.e1 = 1;
      if (this.currentCard.on_raid === true) {
        this.selectedCards = [this.currentCard.id];
      } else {
        this.selectedCards = [];
      }
      this.dialog = false;
      this.where = null;
      this.root = true;
    },
  },
  mounted() {
    this.$http.get(`${this.$api_url}/mounts`).then((res) => {
      this.projects = res.data;
      this.loading = false;
    });
  },
  data() {
    return {
      dialog: false,
      e1: 1,
      selectedCards: [],
      loading: true,
      projects: [],
      where: null,
      root: true,


    };
  },
};
</script>

<style>

</style>
