<template>
<div>
      <v-row>
        <v-col>
        <v-text-field dense v-model="filter" clearable=""
          label="filtrer..."></v-text-field></v-col>
          <v-col cols="1">
            <v-tooltip bottom="">
              <template v-slot:activator="{ on }">
          <v-btn v-on="on" @click="add_selection" icon
          color="primary"><v-icon>mdi-plus-box</v-icon></v-btn>
              </template>
              <span>Ajouter une séléction</span></v-tooltip>
          </v-col>

      </v-row>
 <v-data-iterator
      :items="filterdSelection"
        :items-per-page="5"
:page.sync="pagination"
    >
    <template v-slot:default="props">

     <v-list>

        <v-list-item
        active
        :class="[{ tile : in_selection(item) }, errorClass]"
          v-for="(item) in props.items"
          :key="item.id"
          @click="add_remove(item)"
        >
          <v-list-item-title ><v-icon v-if="in_selection(item)">mdi-minus-box</v-icon>
          <v-icon v-else>mdi-plus-box</v-icon>{{ item.name }}</v-list-item-title>
        </v-list-item>
      </v-list>
</template>


    </v-data-iterator>

</div>
</template>

<script>
import _ from 'lodash';

export default {
  export: 'SelectionComponent',
  data() {
    return {
      filter: '',
      pagination: 1,
    };
  },
  props: {
    selections: {
      type: Array,
    },
    currentClip: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    add_remove(item) {
      this.$emit('loading');
      if (!this.in_selection(item)) {
        this.$http.post(`${this.$api_url}/ar_sel`, {
          id: item.id,
          add: true,
          clip: this.currentClip.id,
        }).then(() => {
          this.$set(this.currentClip.selection, this.currentClip.selection.length, item.id);

          this.pagination = 1;
          this.$emit('loaded');
          this.$dialog.notify.info('Ajouté avec succés', {
            position: 'top-right',
            color: 'primary',
            timeout: 2000,
          });
        });
      } else {
        this.$http.post(`${this.$api_url}/ar_sel`, {
          id: item.id,
          add: false,
          clip: this.currentClip.id,
        }).then(() => {
          this.$delete(this.currentClip.selection,
            this.currentClip.selection.indexOf(item.id));
          this.pagination = 1;
          this.$emit('loaded');
          this.$dialog.notify.info('Retiré avec succés', {
            position: 'top-right',
            color: 'primary',
            timeout: 2000,
          });
        });
      }
    },
    async add_selection() {
      const res = await this.$dialog.prompt({
        text: 'Nom de la connection',
        title: 'Entrez le nom de votre sélection',
      });
      if (res !== undefined && res !== false) {
        if (this.selections.filter(
          value => value.name.toLowerCase() === res.toLowerCase(),
        ).length > 0) {
          this.filter = res;
          this.$set(this.currentClip.selection,
            this.currentClip.selection.length,
            this.selections.filter(value => value.name.toLowerCase() === res.toLowerCase())[0].id);
        } else {
          this.$emit('loading');
          const data = await this.$http.post(`${this.$api_url}/create_sel`, { name: res, clip: this.currentClip.id });
          this.$emit('loaded');
          if (data.data.ok) {
            this.$dialog.notify.info('Collection créée avec succès', {
              position: 'top-right',
              color: 'primary',
              timeout: 2000,
            });
            this.filter = res;
            this.$set(this.selections, this.selections.length, data.data.sel);
            this.$set(this.currentClip.selection, this.currentClip.selection.length,
              data.data.sel.id);
          }
        }
      }
    },
    in_selection(item) {
      return this.currentClip.selection.includes(item.id);
    },
  },
  computed: {

    sortedSelections() {
      const res = [];
      this.selections.forEach((element) => {
        if (this.in_selection(element)) {
          res.push(element);
        }
      });
      this.selections.forEach((element) => {
        if (!res.includes(element)) {
          res.push(element);
        }
      });
      return res;
    },
    filterdSelection() {
      if (!this.filter) {
        return this.sortedSelections;
      }
      return _.filter(this.selections,
        o => o.name.toLowerCase().includes(this.filter.toLowerCase()));
    },
  },
};
</script>
<style scoped>
  .tile {
   background-color: yellowgreen;
  margin: 5px;
  border-radius: 4px;
  }

</style>

and that should do
