<template>
<v-card flat="" :loading="saving">
   <v-form ref="form" >
      <v-text-field label="Pays" v-model="currentClip.pays"
      @keydown.enter.prevent="save"></v-text-field>
      <v-text-field label="Ville" v-model="currentClip.ville"
      @keydown.enter.prevent="save"></v-text-field>
      <v-label>Tags</v-label>
      <tags-editor :tags.sync='currentClip.tags'></tags-editor>
      <v-textarea label="Commentaire" v-model="currentClip.comment"></v-textarea>
<v-btn @click="save()" class="success" >Sauver</v-btn>
      </v-form></v-card>
</template>

<script>
import _ from 'lodash';
import TagsEditor from './TagsEditor.vue';

export default {
  name: 'ClipEditor',
  mounted() {
    this.originalClip = _.clone(this.currentClip, true);
  },
  methods: {
    async save() {
      this.saving = true;
      await this.saveClip();
      this.originalClip = _.clone(this.currentClip, true);

      this.saving = false;
    },
    async saveClip() {
      this.$emit('loading');
      const a = await this.$http.post(`${this.$api_url}/update_clip`, { clip: this.currentClip });
      return a;
    },
    async changed() {
      if (_.isEqual(this.currentClip, this.originalClip)) {
        return true;
      }
      const re = await this.$confirm('Le clip a été modifié voulez vous sauvegarder les changement ?');
      if (re === false) {
        this.$set(this.currentClip, 'pays', this.originalClip.pays);
        this.$set(this.currentClip, 'ville', this.originalClip.ville);
        this.$set(this.currentClip, 'ville', this.originalClip.comment);
        this.$set(this.currentClip, 'tags', this.originalClip.tags);
        return true;
      }
      const response = await this.saveClip();
      if (response.error) {
        this.$set(this.currentClip, 'pays', this.originalClip.pays);
        this.$set(this.currentClip, 'ville', this.originalClip.ville);
        this.$set(this.currentClip, 'ville', this.originalClip.comment);
        this.$set(this.currentClip, 'tags', this.originalClip.tags);
        return false;
      }
      this.originalClip = _.clone(this.currentClip, true);
      return true;
    },

  },
  data() {
    return {
      originalClip: {},
      saving: false,
    };
  },
  props: {
    currentClip: {
      type: Object,
      default: () => {},
    },
  },

  components: {
    TagsEditor,
  },
  watch: {
    currentClip() {
      this.originalClip = _.clone(this.currentClip, true);
    },
  },

};
</script>

<style>

</style>
