<template>
<v-row>
  <v-dialog v-model="dialog">
    <clip-edit-dialog :readOnly="readOnly" @cancel='closeDialog()' v-if="dialog"
 :canNext="canNext"
  :canPrevious=canPrevious
    :currentClip.sync=currentClip
    @previous="previous"
    :selections=selections
    @next="next"></clip-edit-dialog>
  </v-dialog>
<v-col>
<v-card :loading="child_loading">
    <v-card-title v-if="!loading">{{currentCard.name}}</v-card-title>
    <v-card-text >
        <v-data-table color="accent" v-if="!loading"
        :items="currentCard.clips" :headers="headers"
        v-model="currentClip">

              <template v-slot:item.id="{ item }">

                  <v-avatar v-if="$vuetify.breakpoint.xs">
                  <img v-if="!readOnly" :src="$api_url+'/asset_th/'+item.id" >
                  <img v-else :src ="item.thumb">

                  </v-avatar>
                  <div v-else>
                  <img  v-if="!readOnly" :src="$api_url+'/asset_th/'+item.id">
                   <img v-else :src ="item.thumb"></div>
              </template>
              <template v-slot:item.tags="{ item }">
                 <v-chip v-for="t in item.tags" :key="t.id">{{t.text}}</v-chip>
              </template>
              <template v-slot:item.action="{ item }">
                <v-tooltip bottom>
           <template v-slot:activator="{ on }">
             <v-btn icon=""   @click="currentClip=[item]" v-on="on">
               <v-icon>mdi-eye</v-icon>
               </v-btn>
           </template>
           <span>Détails</span></v-tooltip>


    </template>
        </v-data-table>
    </v-card-text>
</v-card ></v-col>

</v-row>
</template>

<script>
import ClipEditDialog from './ClipEditDialog.vue';

export default {

  name: 'ClipTable',
  methods: {

    closeDialog() {
      this.dialog = false;
    },

    previous() {
      const index = this.currentCard.clips.indexOf(this.currentClip[0]) - 1;
      if (index < 0) {
        return;
      }
      this.currentClip = [this.currentCard.clips[index]];
      this.$emit('update:currentClipId', this.currentClip[0].id);
    },
    async next() {
      const index = this.currentCard.clips.indexOf(this.currentClip[0]) + 1;
      if (index <= this.currentCard.clips.length - 1) {
        this.$emit('update:currentClipId', this.currentCard.clips[index].id);
        this.currentClip = [this.currentCard.clips[index]];
      }
    },
  },
  components: {
    ClipEditDialog,
  },
  watch: {
    dialog(newValue) {
      if (newValue === false) {
        this.$emit('update:currentClipId', null);
      } else {
        this.$emit('update:currentClipId', this.currentClip[0].id);
      }
    },
    currentClip(newValue) {
      if (newValue.length > 0) {
        this.dialog = true;
      } else {
        this.dialog = false;
      }
    },
  },
  data() {
    return {
      currentClip: [],
      child_loading: false,
      dialog: false,
      headers: [{
        text: '',
        align: 'left',
        sortable: false,
        value: 'id',
      },
      {
        text: 'Nom',
        align: 'left',
        sortable: true,
        value: 'name',
      },
      {
        text: 'Description',
        align: 'left',
        sortable: false,
        value: 'comment',
      },
      {
        text: 'Tags',
        align: 'left',
        sortable: false,
        value: 'tags',
      },
      {
        text: 'Pays',
        align: 'left',
        sortable: false,
        value: 'pays',
      }, {
        text: 'Ville',
        align: 'left',
        sortable: false,
        value: 'ville',
      },
      { text: 'Actions', value: 'action', sortable: false },
      ],
    };
  },
  computed: {
    canNext() {
      const index = this.currentCard.clips.indexOf(this.currentClip[0]) + 1;
      if (index <= this.currentCard.clips.length - 1) {
        return false;
      }
      return true;
    },
    canPrevious() {
      const index = this.currentCard.clips.indexOf(this.currentClip[0]) - 1;

      if (index < 0) {
        return true;
      }
      return false;
    },


  },
  props: {
    readOnly: {
      type: Boolean,
      default: false,
    },
    currentCard: {
      type: Object,
      default: () => ({}),
    },
    currentClipId: {
      type: Number,
      default: null,
    },
    loading: { type: Boolean },
    selections: { type: Array },
  },

};
</script>

<style>

</style>
