<template>
<v-card>

<v-card-title>  <v-btn @click="$emit('cancel')" icon>
    <v-icon>mdi-close</v-icon></v-btn>&nbsp;{{valid.name}}

</v-card-title>
<v-card-text>
  <v-row>
     <v-tabs @change="changed" v-model="currentTab"
     show-arrows centered background-color="secondary">
            <v-tab>
                <v-icon left>mdi-play</v-icon>
                Lecture
            </v-tab>
            <v-tab v-if="valid.ready">
                <v-icon left>mdi-camera</v-icon>
                Miniature
            </v-tab>
            <v-tab v-if="this.$store.state.userData.admin">
                <v-icon left>mdi-pencil</v-icon>
                Editer
            </v-tab>
            <v-tab>
                <v-icon left>mdi-share</v-icon>
                partager
            </v-tab>
            <v-tab-item >
               <v-card flat>
                    <v-card-text>
                        <v-row>

                                <v-card flat="" v-if="valid.ready" ref="playerDiv">


                                    <v-card-text>
                                      <v-row>
                                        <v-col>
                                           <hls-player ref="player"
                                    :playing="play"
                                    :thumb="imgSrc" :autoplay="false"
                                    :source="valid.hls"></hls-player>
                                        </v-col>
                                        <v-col>
                                        <h4>Commentaires : </h4>
                                        {{valid.comment}}
                                        <h4>Ajouté par : </h4>
                                        {{valid.user}}
                                        <h4>Date d'ajout : </h4>
                                        {{valid.date}}
                                        <br>
                                        <v-btn color="red" download target="_blank"
                                        :href="$api_url+'/valid_dl/'+valid.uuid+'.mp4'">
                                        <v-icon>mdi-download</v-icon>Télécharger</v-btn>
                                        <v-btn color="green" :to="'/validation/'+valid.id+'/edit'">
                                        <v-icon>mdi-comment</v-icon>Commenter </v-btn>
                                        </v-col>
                                        </v-row>
                                    </v-card-text>

                                </v-card>

                            <v-card flat v-else>
                                <master-progress :master="valid"></master-progress>
                            </v-card>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-tab-item>
            <v-tab-item v-if="valid.ready">
              <thumb-changer  @cancel="currentTab=0" :master="valid"
              :prefix="'valid'" ></thumb-changer>
            </v-tab-item>
            <v-tab-item v-if="this.$store.state.userData.admin">
              <valid-edit  @reload="deleted" @cancel="currentTab=0" :valid="valid"></valid-edit>
            </v-tab-item>
            <v-tab-item>
                   <valid-share :valid="valid" ></valid-share>
            </v-tab-item>
     </v-tabs>

  </v-row>

</v-card-text>
</v-card>
</template>

<script>
import HlsPlayer from '../player/HlsPlayer.vue';
import ThumbChanger from '../ThmubChanger.vue';
import ValidShare from './ValidShare.vue';
import ValidEdit from './ValidEdit.vue';

export default {
  name: 'ValidDetails',
  components: {
    HlsPlayer, ThumbChanger, ValidShare, ValidEdit,
  },
  methods: {
    changed() {
      if (this.$refs.player) { this.$refs.player.player.pause(); }
    },
    deleted() {
      this.$emit('reload');
      this.$emit('cancel');
    },
  },
  data() {
    return {
      currentTab: 0,
      play: false,

    };
  },
  watch: {
    imgSrc(newValue) {
      if (this.$refs.player !== undefined) {
        this.$refs.player.player.configure({ poster: newValue });
      }
    },
  },
  computed: {
    imgSrc() {
      return `${this.$api_url}/valid_th/${this.valid.uuid}.jpg?${this.valid.key}`;
    },
  },
  props: {
    valid: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style>

</style>
