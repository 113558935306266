<template>
<v-card >
    <v-dialog v-model="dialog" max-width="80%">

        <master-details ref="mastDetail" @reload="loadMaseters" v-if="dialog"
        @cancel="selected=[]" :currentMaster.sync="selected[0]"></master-details>

    </v-dialog>
    <v-card-title>Masters</v-card-title>
    <v-card-text>

        <collection-switch v-model="collectionGroup"></collection-switch>
        <v-row>
            <v-col v-if="updating">
                <v-progress-circular color="primary" indeterminate></v-progress-circular>
                Actualisation...
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="6">
                <v-text-field v-model="search" append-icon="mdi-magnify"
                label="Chercher..." single-line clearable=""  hide-details></v-text-field>
            </v-col>
        </v-row>

        <v-data-table single-select :loading="loading" :headers="headers"
        :search="search"
        v-touch="{
      left: () => swipe('Left'),
      right: () => swipe('Right'),

    }"
        no-results-text= "Aucun master trouvé"
        :items="masters" item-key="id" :items-per-page.sync="itemPerPage"
        :page.sync="page">

         <template v-slot:item.name="{ item }">
                 <b><text-highlight :queries="light">{{item.name}}</text-highlight></b>
             </template>
                   <template v-slot:item.collection="{ item }"></template>
            <template v-slot:item.group="{ item }"></template>
              <template v-slot:item.comment="{ item }">
                 <text-highlight :queries="light">{{item.comment}}</text-highlight>
             </template>
            <template v-slot:item.watermark="{ item }">
                <v-chip v-if="item.watermark" class="red white--text" left>W</v-chip>
                <v-chip v-else class="green white--text" left>M</v-chip>
            </template>
            <template v-slot:item.id="{ item }">
                <v-avatar>
                    <v-img :eager="true" :aspect-ratio="16/9"
                    :src="ImgUrl + item.id +'/' + $store.state.token +'.jpg'+'?'+item.key"
                    width="100px" />                </v-avatar>
            </template>

            <template v-slot:item.action="{ item }">
 <v-tooltip bottom>
      <template v-slot:activator="{ on }">
                <v-btn v-on="on" icon @click="selected = [item]">

                    <v-icon>
                        mdi-eye
                    </v-icon>
                </v-btn>
      </template>

        <span>Voir les détails</span>
      </v-tooltip>

      <v-tooltip bottom>
      <template v-slot:activator="{ on }">
                <v-btn v-on="on" icon @click="shareMaster(item)">

                    <v-icon>
                        mdi-share
                    </v-icon>
                </v-btn>
      </template>

        <span>Partager</span>
      </v-tooltip>
            </template>
        </v-data-table>

    </v-card-text>
</v-card>
</template>

<script>
import _ from 'lodash';

import CollectionSwitch from './CollectionSwitch.vue';
import MasterDetails from './MasterDetails.vue';

export default {
  name: 'MastersTable',
  filters: {
    truncate(text, length, suffix) {
      if (length < text.length) {
        return text.substring(0, length) + suffix;
      }
      return text;
    },
  },
  components: {
    CollectionSwitch,
    MasterDetails,
  },
  mounted() {
    this.loadMaseters();
    this.search = this.$route.query.filter;
    const quer = _.clone(this.$route.query);
    if (quer.page && quer.of) {
      this.itemPerPage = parseInt(quer.of, 10);
      this.page = parseInt(quer.page, 10);
    } else {
      this.page = 1;
      this.itemPerPage = 5;
    }
  },
  methods: {
    swipe(direction) {
      if (direction === 'Left') {
        this.page = this.page + 1;
      } else
      if (this.page > 0) {
        this.page = this.page - 1;
      }
    },
    shareMaster(item) {
      this.selected = [item];
      this.$nextTick(() => {
        if (this.$store.state.userData.admin) {
          this.$refs.mastDetail.currentTab = 3;
        } else {
          this.$refs.mastDetail.currentTab = 2;
        }
      });
    },
    loadMaseters() {
      let tempMasters = localStorage.getItem('masters');
      if (tempMasters != null) {
        this.masters = JSON.parse(tempMasters);
        this.loading = false;
      } else {
        localStorage.setItem('masters', JSON.stringify([]));
        tempMasters = localStorage.getItem('masters');
      }
      this.updating = true;

      this.$http.get(`${this.$api_url}/masters`).then((res) => {
        this.loading = false;
        localStorage.setItem('masters', JSON.stringify(res.data));
        if (!_.isEqual(JSON.parse(tempMasters).sort(), res.data.sort())) {
          this.masters = res.data;
        }
        if (this.$route.params.master_id) {
          this.selected = [_.find(this.masters,
            o => o.id === parseInt(this.$route.params.master_id, 10))];
        }
        this.updating = false;
      });
    },
    groupFilter(value) {
      if (this.collectionGroup.currentGroup === undefined) {
        return true;
      }
      return value === this.collectionGroup.currentGroup;
    },
    collectionFilter(value) {
      if (this.collectionGroup.currentCol === undefined) {
        return true;
      }
      return value === this.collectionGroup.currentCol;
    },

  },
  computed: {
    light() {
      if (this.search) {
        return [this.search];
      }
      return '';
    },
    computedHeaders() {
      return this.headers.filter(word => word.text !== 'Collection' && word.text !== 'Group');
    },

  },
  watch: {
    itemPerPage(value) {
      const quer = _.clone(this.$route.query);
      quer.of = value;
      this.$router.push({ path: this.$route.path, query: quer }).catch(() => {});
    },
    page(value) {
      const quer = _.clone(this.$route.query);
      quer.page = value;
      this.$router.push({ path: this.$route.path, query: quer }).catch(() => {});
    },
    search(value) {
      const quer = _.clone(this.$route.query);
      if (value === null) {
        quer.filter = undefined;
      } else {
        quer.filter = value;
      }

      this.$router.push({
        path: this.$route.path,
        query: quer,
      }).catch(() => {});
    },
    dialog(value) {
      if (value === false) {
        const quer = _.clone(this.$route.query);
        quer.page = this.page;
        quer.of = this.itemPerPage;
        quer.filter = this.filter;
        this.$router.push({ path: '/masters', query: quer }).catch(() => {});
      }
    },
    selected(newValue) {
      this.dialog = true;
      if (newValue[0]) {
        this.$router.push({
          name: 'master_select',
          params: {
            master_id: newValue[0].id,
          },
        }).catch(() => {});
      } else {
        this.dialog = false;
      }
    },
  },
  data() {
    return {
      ImgUrl: `${this.$api_url}/master_thumb/`,
      search: '',
      loading: true,
      updating: false,
      masters: [],
      dialog: false,
      page: 1,
      itemPerPage: 5,
      collectionGroup: {
        currentCol: undefined,
        currentGroup: undefined,
      },
      selected: [],
      headers: [{
        text: 'Type',
        align: 'left',
        sortable: false,
        value: 'watermark',
        filterable: false,
      },
      {
        text: 'Image',
        align: 'left',
        sortable: false,
        value: 'id',

      },
      {
        text: 'Nom',
        value: 'name',

      },
      {
        text: 'Rangé dans...',
        value: 'path',

      },
      {
        text: '',
        value: 'collection',
        filter: this.collectionFilter,
        sortable: false,

      },
      {
        text: '',
        value: 'group',
        filter: this.groupFilter,
        sortable: false,

      },
      {
        text: 'Utilisateur',
        value: 'user',
        filterable: false,
      },
      {
        text: 'Commentaire',
        value: 'comment',

      },
      {
        text: 'Date',
        value: 'date',
        filterable: false,
      },
      {
        text: 'Actions',
        value: 'action',
        sortable: false,
        filterable: false,
      },


      ],

    };
  },
};
</script>

<style scoped>
tr {
    cursor: pointer;
}
</style>
