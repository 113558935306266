<template>
  <div>
    <span class="overline">{{ comments[0].open_by_display }}</span><br>
        &nbsp;
    <user-comment-object
      style="cursor: pointer;"
      v-for="comment in comments"
      :key="comment.time"
      :comment="comment"
      :factor="factor"
      @commentSelected="select"
      :selected="slectedComment === comment"
    />
  </div>
</template>

<script>
import UserCommentObject from './UserCommentObject.vue';

export default {
  name: 'UserComments',

  props: {
    comments: {
      type: Array,
    },

    factor: {
      type: Number,
      default: 1,
    },
    slectedComment: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  methods: {
    select(comment) {
      this.$emit('update:slectedComment', comment);
    },
  },
  components: {
    UserCommentObject,
  },

};
</script>

<style lang="scss" scoped>

</style>
