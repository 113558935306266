<template>
<v-container>
     <v-skeleton-loader class="mx-auto" max-width="400" type="card" :loading="loading">
   <v-card :loading="card_loading" v-if="!granted">
       <v-img
      class="white--text align-end"
      height="200px"
      :src=project.thumb
    >
      <v-card-title>{{project.name}}</v-card-title>

    </v-img>
     <v-card-text>
         Pour accéder au projet, vous devez entrer un mot de passe :
            <v-text-field v-model="passw"
             :error-messages="error_msg" @keyup="error_msg =''"
             @keyup.enter.prevent="grant_access"
             type="password" prepend-icon="mdi-lock"></v-text-field>
     </v-card-text>
     <v-card-actions>
         <v-spacer></v-spacer>
         <v-btn class="success" @click="grant_access">Valider</v-btn>
     </v-card-actions>
   </v-card>
   <project-deliver-details :project="grant_project" v-if="granted"></project-deliver-details>
     </v-skeleton-loader>
</v-container>
</template>

<script>
import ProjectDeliverDetails from '../components/projectDeliver/ProjectDeliverDetails.vue';

export default {
  name: 'ProjectDeliver',
  components: {
    ProjectDeliverDetails,
  },
  methods: {
    grant_access() {
      this.card_loading = true;
      this.$http.post(`${this.$api_url}/public_request_projectshare`, {
        uuid: this.$route.params.share_id,
        passw: this.passw,
      }).then((res) => {
        this.card_loading = false;
        if (res.data.granted === false) {
          this.error_msg = 'Mauvais mot de passe';
          return;
        }
        this.grant_project = res.data.granted_project.project;
        this.granted = true;
      }).catch(() => {
        this.card_loading = false;
        this.$dialog.notify.error('une erreur est survenue', {
          position: 'top-centered',
          timeout: 10000,
        });
      });
    },
  },
  mounted() {
    this.$http.get(`${this.$api_url}/public_project_share/${this.$route.params.share_id}`).then((res) => {
      this.project = res.data;
      this.loading = false;
    }).catch(() => {
      this.$dialog.notify.error('Impossible de charger le partage demandé', {
        position: 'top-centered',
        timeout: 10000,
      });
    });
  },
  data() {
    return {
      loading: true,
      project: {},
      card_loading: false,
      granted: false,
      passw: '',
      error_msg: '',
      grant_project: {},
    };
  },
};
</script>

<style>

</style>
