<template>
<v-col>
   <v-tooltip bottom>
     <template v-slot:activator="{ on }">
           <v-btn @click="dialog=true" v-on="on" icon>
               <v-icon>mdi-share</v-icon></v-btn></template>
           <span>Partager le projet</span> </v-tooltip>
           <v-dialog v-model="dialog" width="50%">
               <v-card :loading="loading" v-if="dialog">
                   <v-img
      class="white--text align-end"
      height="200px"
      :src="$api_url+'/asset_th/'+currentProject.cards[0].clips[0].id"
    >
     <v-card-title>Partager {{currentProject.name}}</v-card-title>
                   <v-card-subtitle>Le projet sera acessible à toute personne
                       disposant du lien </v-card-subtitle>
    </v-img>

                       <v-card-text v-if="granted === false">
                               <v-form ref="form">
                                  <v-text-field label="Nom du partage"
          required v-model="shareName"
                  @change="error_message = ''"
           :error-messages="error_message"></v-text-field>
            <v-label>Durée du partage</v-label>
            <v-slider v-model="duration" :tick-labels="ticksLabels"
            :max="1" step="1" ticks="always" tick-size="4"></v-slider>
<v-btn @click="dialog=false">Annuler</v-btn>

<v-btn @click="share_project" class="success float-right">Patrager</v-btn>
        </v-form>
                       </v-card-text>
                       <v-card-text v-else  class="white black--text">
    <div><b>Lien de partage :</b><br>{{$base_url}}/#/projectShare/{{uuid}}</div>
    <div><b>Mot de passe : </b>{{pass}}</div>
                       </v-card-text>
                        <v-card-actions v-if="granted">
       <v-btn
        color="accent"
        icon
        @click="dialog=false"
      >
      <v-icon>
        mdi-arrow-left</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-btn color="orange" icon @click="copy">
            <v-icon>
                mdi-content-copy</v-icon>
        </v-btn>

    </v-card-actions>
               </v-card>
           </v-dialog>
</v-col>
</template>

<script>
import moment from 'moment';

export default {
  name: 'SharePorjectComponent',
  mounted() {
    this.$nextTick(() => {
      this.shareName = `${this.currentProject.name}_${moment().locale('fr').format('LLL')}`;
    });
  },
  watch: {
    dialog(newValue) {
      if (newValue === false) {
        this.granted = false;
        this.uuid = '';
        this.pass = '';
        this.duration = 0;
      }
    },
  },
  methods: {
    copy() {
      navigator.clipboard.writeText(`Lien : ${this.$base_url}/#/projectShare/${this.uuid}\nMot de passe : ${this.pass}`)
        .then(() => {
          this.$dialog.message.info('Lien copié avec succès', {
            position: 'top-left',

          });
        })
        .catch(() => {
          // This can happen if the user denies clipboard permissions:
          this.$dialog.message.error('Votre navigateur ne supporte pas cette opération', {
            position: 'top-left',

          });
        });
    },
    share_project() {
      if (this.shareName === '') {
        this.error_message = 'Un nom est requis';
        return;
      }
      this.loading = true;
      this.$http.post(`${this.$api_url}/share_project`, {
        id: this.currentProject.id,
        duration: this.duration,
        name: this.shareName,
      }).then((res) => {
        this.loading = false;
        this.granted = true;
        this.uuid = res.data.uuid;
        this.pass = res.data.pass;
      }).catch(
        this.loading = false,
      );
    },
  },
  data() {
    return {
      dialog: false,
      shareName: '',
      error_message: '',
      loading: false,
      uuid: '',
      pass: '',
      granted: false,
      duration: 0,
      ticksLabels: [
        '7 jours',
        '1 mois',
      ],
    };
  },
  props: {
    currentProject: {
      type: Object,
      default: () => {},
    },
  },

};
</script>

<style>

</style>
