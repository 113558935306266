<template>


      <masters-table v-if="this.$store.state.userData.masters"></masters-table>
      <div v-else>
        Votre compte ne vous permet pas de visualiser les masters
      </div>


</template>
<script>
import MastersTable from '../components/MastersTable.vue';

export default {
  components: {
    MastersTable,
  },
};
</script>
