<template>
<v-app id="inspire" class="d-flex align-stretch">
      <v-overlay :value="overlay" z-index="9999">

      <v-card color="red"><v-card-title>Erreur réseau  <v-btn
        icon
        @click="overlay = false"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn></v-card-title>
      <v-card-text v-if="overlay">
          <h3>Une erreur réseau s'est produite</h3>

            Url : {{error.data.config.url}}<br>
            Réponse : {{error.data.response}}<br>
            vous pouvez essayer de <v-btn text @click="reload">Recharger la page</v-btn>
          </v-card-text></v-card>
    </v-overlay>
    <v-navigation-drawer v-if="logged" v-model="drawer" app clipped>
        <v-list dense>
            <v-list-item to='/'>
                <v-list-item-action>
                    <v-icon>mdi-home</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>
                        Accueil
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item to='/projects?page=1'>
                <v-list-item-action>
                    <v-icon>mdi-video</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>
                        Tournages
                    </v-list-item-title>
                </v-list-item-content>

            </v-list-item>
            <v-list-item to='/masters?page=1' v-if='userData.masters === true'>
                <v-list-item-action>
                    <v-icon>mdi-youtube</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>
                        Masters
                    </v-list-item-title>
                </v-list-item-content>

            </v-list-item>
            <v-list-item to='/validations?page=1'  v-if='userData.validation === true'>
                <v-list-item-action>
                    <v-icon>mdi-message-video</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>
                        Validations
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
     <v-list-item to='/recherche'>
                <v-list-item-action>
                    <v-icon>mdi-magnify</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>
                        Recherche
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-subheader class="mt-4 grey--text text--darken-1">OUTILS</v-subheader>
            <v-list-item class="mt-4" to="/shares/masters" >
                <v-list-item-action>
                    <v-icon color="grey darken-1">mdi-share-variant</v-icon>
                </v-list-item-action>
                <v-list-item-title class="grey--text text--darken-1">
                  Mes partages</v-list-item-title>
            </v-list-item>
             <v-list-item class="mt-4" to="/selections" >
                <v-list-item-action>
                    <v-icon color="grey darken-1">mdi-cart</v-icon>
                </v-list-item-action>
                <v-list-item-title class="grey--text text--darken-1">
                  Mes Sélections</v-list-item-title>
            </v-list-item>

             <v-list-item class="mt-4" to="/persoSpace" >
                  <v-list-item-action>
                    <v-icon color="grey darken-1">mdi-folder-account</v-icon>
                </v-list-item-action>
             <v-list-item-title class="grey--text text--darken-1">
                  Mon espace personnel</v-list-item-title>
            </v-list-item>
             <v-list-item class="mt-4" to="/profile" >
                  <v-list-item-action>
                    <v-icon color="grey darken-1">mdi-account</v-icon>
                </v-list-item-action>
             <v-list-item-title class="grey--text text--darken-1">
                  Mon profil</v-list-item-title>
            </v-list-item >
            <v-list-item class="mt-4" to="/apps">
                <v-list-item-action>
                    <v-icon color="grey darken-1">mdi-apps</v-icon>
                </v-list-item-action>
                <v-list-item-title class="grey--text text--darken-1">Logiciels</v-list-item-title>
            </v-list-item>
        </v-list>
    </v-navigation-drawer>
        <v-app-bar app clipped-left :color="drawer_color" dense v-if="logged">
        <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
         <v-img :src="require('@/assets/G.png')" style="max-height: 30px; max-width: 30px;"></v-img>
        <v-toolbar-title class="mr-12 align-center">
            <span class="title">Mam-Explorer</span>
        </v-toolbar-title>
        <div class="flex-grow-1"></div>
        <user-menu></user-menu>
    </v-app-bar>
            <v-container>
         <v-content>
           <v-alert  v-if="ie"  type="error" z-index="9996">
      Votre navigateur est ancien. Le site risque de ne pas foncitonner correctement.
      <a href = "https://browser-update.org/fr/update.html">En savoir plus...</a>
    </v-alert>
            <router-view></router-view>
         </v-content>
        </v-container>

</v-app>
</template>

<script>
import EventBus from './utils';
import UserMenu from './components/UserMenu.vue';

export default {
  components: {
    UserMenu,
  },
  data: () => ({
    drawer: null,
    overlay: false,
    error: {},
  }),
  methods: {
    reload() {
      window.location.reload();
    },
  },
  computed: {
    ie() {
      {
        const ua = window.navigator.userAgent;
        const msie = ua.indexOf('MSIE ');

        if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv:11\./)) {
          return true;
        }


        return false;
      }
    },
    logged() {
      return (this.$store.getters.isAuthenticated);
    },
    drawer_color() {
      if (this.$vuetify.theme.dark === true) {
        return '#a36926';
      }
      return '#d1593b';
    },
    userData() {
      return this.$store.state.userData;
    },
  },
  watch: {
    userData() {
      this.$vuetify.theme.dark = this.$store.state.userData.settings.dark;
    },
  },
  mounted() {
    EventBus.$on('networkError', (e) => {
      this.overlay = true;
      this.error = e;
    });
    this.$vuetify.theme.dark = true;
    if (this.userData.settings) {
      this.$vuetify.theme.dark = this.$store.state.userData.settings.dark;
    }
  },
};
</script>
