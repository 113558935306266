<template>
<v-col id='player' v-resize="resize_player" width="100%" ref="video_player"></v-col>
</template>

<script>
import LevelSelector from 'level-selector';
import Clappr from 'clappr';
import PlaybackRatePlugin from 'clappr-playback-rate-plugin';

export default {
  name: 'HlsPlayer',
  mounted() {
    this.init_player();
  },
  data() {
    return {
      player: null,
      player_div: null,
      currentTime: 0,
      max: 0,
    };
  },
  props: {
    autoplay: {
      type: Boolean,
      default: true,
    },
    source: {
      type: String,
      default: '',
    },
    playing: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: '100%',
    },
    height: Number,
    thumb: {
      type: String,
      default: '',
    },
  },
  watch: {
    source(newValue) {
      this.player.configure({ source: newValue });
      this.player.play();
    },
  },
  methods: {
    resize_player() {
      if (this.player !== null) {
        this.player.resize({
          width: this.$refs.video_player.clientWidth - 20,
          height: (this.$refs.video_player.clientWidth - 20) / 1.7,
        });
      }
    },
    pause() {
      if (this.playing === true) {
        this.player.pause();
        this.$emit('update:playing', false);
      }

      this.$emit('update:playing', false);
    },
    playPause() {
      if (this.playing === true) {
        this.player.pause();

        this.$emit('update:playing', false);
      } else {
        this.player.play();
        this.$emit('update:playing', true);
      }
    },
    updateTime(e) {
      this.currentTime = e.current;
      this.$emit('currentTimeChanged', e.current);
    },
    post_init(duration) {
      this.max = duration.duration;
      this.$emit('durationChanged');
    },
    init_player() {
      this.player_div = this.$refs.video_player;
      this.player = new Clappr.Player({
        source: this.source,


        events: {
          onTimeUpdate: this.updateTime,
          onPlay: () => {
            this.$emit('update:playing', true);
          }, // Fired when player starts to play
          onPause: () => {
            this.$emit('update:playing', false);
          }, // F

        },
        poster: this.thumb,
        plugins: {
          core: [Clappr.MediaControl, PlaybackRatePlugin, LevelSelector],
        },

        levelSelectorConfig: {
          title: 'Quality',
          labels: {
            2: 'HD', // 500kbps
            1: 'Moyenne', // 240kbps
            0: 'Basse', // 120kbps
          },
        },
      });
      this.player.attachTo(this.$refs.video_player);
      this.resize_player();
      this.player.core.getCurrentContainer().on(
        Clappr.Events.CONTAINER_LOADEDMETADATA,
        this.post_init,
      );
      if (!this.controls) {
        this.player.core.mediaControl.disable();
      }
      // this.player.attachTo(this.$refs.video_player);
      if (this.autoplay === true) {
        this.player.play();
      }
    },
  },
};
</script>

<style >

</style>
