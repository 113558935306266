var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-dialog',{attrs:{"max-width":"290"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[(_vm.currentShare)?_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("Etendre la durée de "+_vm._s(_vm.currentShare.project.name))]),_c('v-card-text',[_vm._v(" Choisir la durée de partage suppélemtaire : "),_c('v-slider',{attrs:{"tick-labels":_vm.ticksLabels,"max":1,"step":"1","ticks":"always","tick-size":"4"},model:{value:(_vm.duration),callback:function ($$v) {_vm.duration=$$v},expression:"duration"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Annuler ")]),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":_vm.grantExtention}},[_vm._v(" Etendre ")])],1)],1):_vm._e()],1),_c('v-flex',{staticStyle:{"overflow":"auto"},attrs:{"md12":""}},[_c('v-data-table',{attrs:{"loading":_vm.loading,"items":_vm.ordered_shares,"headers":_vm.headers,"search":_vm.searchTerm},scopedSlots:_vm._u([{key:"body.prepend",fn:function(ref){
var headers = ref.headers;
return [_c('tr',{staticClass:"secondary"},[_c('td',{attrs:{"colspan":headers.length/2}},[_c('v-btn',{attrs:{"color":"red"},on:{"click":_vm.purge_link}},[_vm._v("Supprimer tous les liens périmés")])],1),_c('td',{attrs:{"colspan":headers.length/2}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Chercher...","clearable":"","single-line":"","hide-details":""},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}})],1)])]}},{key:"item.expires",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.expires))+" ")]}},{key:"item.expired",fn:function(ref){
var item = ref.item;
return [(item.expired == true)?_c('v-avatar',{attrs:{"color":"red"}},[_vm._v("Périmé")]):_c('v-avatar',{attrs:{"color":"green"}},[_vm._v("Actif")])]}},{key:"item.project.id",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',[_c('v-img',{attrs:{"eager":true,"src":_vm.$api_url + '/project_th/'
      +item.project.id,"max-height":"60px"}})],1)]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.deleteShare(item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [(item.expires !== 'Jamais')?_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){$event.preventDefault();return _vm.extendDialog(item)}}},on),[_c('v-icon',[_vm._v("mdi-lock-reset")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Etendre la durée")])])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }