<template>
<v-row>

<v-row>
    <v-col>
<h1>{{project.name}}</h1></v-col>
</v-row>
<v-row>
    <v-col>
<v-card height="100%">
                        <v-card-title> Cartes </v-card-title>
                        <v-card-text>

                            <v-select :items="cards" v-model="currentCardId"></v-select>

                            <v-divider></v-divider>
                        </v-card-text>
</v-card></v-col>
</v-row>
<v-row>
    <clip-table ref="clipTable" :currentClipId.sync="currentClipId" :readOnly="true"
            :selections="[]" :loading="currentCard.length < 0 === undefined"
            :currentCard="currentCard[0]" v-if="currentCard.length > 0">
            </clip-table>
</v-row>
</v-row>
</template>

<script>
import ClipTable from '../clips/ClipTable.vue';

export default {
  components: {
    ClipTable,
  },
  name: 'ProjectDeliverDetails',
  data() {
    return {
      currentCardId: 0,
      currentClipId: undefined,
    };
  },
  mounted() {
    this.currentCardId = this.project.cards[0].id;
  },
  computed: {

    currentCard() {
      return this.project.cards.filter(e => e.id === this.currentCardId);
    },
    cards() {
      const res = [];
      this.project.cards.forEach((e) => {
        res.push({
          text: e.name,
          value: e.id,
        });
      });
      return res;
    },
  },
  props: {
    project: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style>

</style>
