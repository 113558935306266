<template>
<v-row>
    <v-col>
        <v-card>
            <v-card-title>Logiciels MAM</v-card-title>
            <v-card-text>
                <v-list>

                    <v-list-item>
                        <v-list-item-avatar>
                            <v-icon>mdi-cloud-upload</v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title>MamUploader </v-list-item-title>
                            <v-list-item-subtitle>Cet outil vous permet d'envoyer
                                vos validation et masters
                                depuis n'importequel logiciel de montage.
                                Ou par simple glisser-déposer</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            Télécharger : <v-spacer></v-spacer>
                            <v-btn icon :href="$base_url +
                            '/static/apps/uploader/MamUploader.dmg'"
                           >
                                <v-icon>mdi-apple</v-icon>
                            </v-btn>
                            <v-btn icon  :href="$base_url +
                            '/static/apps/uploader/MamUploader-win-latest.zip'">
                                <v-icon>mdi-microsoft-windows</v-icon>
                            </v-btn>
                                 <v-btn icon :href="$base_url +
                            '/static/apps/uploader/MamUploader-rhl-latest.zip'">
                                <v-icon>mdi-linux</v-icon>
                            </v-btn>
                        </v-list-item-action>
                    </v-list-item>

                    <v-list-item>
                        <v-list-item-avatar>
                            <v-icon>mdi-database</v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title>MamManager </v-list-item-title>
                            <v-list-item-subtitle>Gestion et
                                administration du MAM.</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            Télécharger :

                             <v-spacer></v-spacer>
                            <v-btn icon :href="$base_url +
                            '/static/apps/manager/MamManager-mac-latest.tar.gz'">
                                <v-icon>mdi-apple</v-icon>
                            </v-btn>
                            <v-btn icon :href="$base_url +
                            '/static/apps/manager/MamManager-win-latest.zip'">
                                <v-icon>mdi-microsoft-windows</v-icon>
                            </v-btn>
                            <v-btn icon :href="$base_url +
                            '/static/apps/manager/MamManager-rhl-latest.zip'">
                                <v-icon>mdi-linux</v-icon>
                            </v-btn>
                        </v-list-item-action>
                    </v-list-item>

      <v-list-item>
                        <v-list-item-avatar>
                            <v-icon>mdi-format-list-bulleted</v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title>EdlTools </v-list-item-title>
                            <v-list-item-subtitle>Transformation des
                                EDL en XLSX.</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            Télécharger : <v-spacer></v-spacer>
                            <v-btn icon :href="$base_url +
                            '/static/apps/edl/EDLTools-mac-latest.tar.gz'">
                                <v-icon>mdi-apple</v-icon>
                            </v-btn>
                            <v-btn icon :href="$base_url +
                            '/static/apps/edl/EDLTools-win-latest.zip'">
                                <v-icon>mdi-microsoft-windows</v-icon>
                            </v-btn>
                        </v-list-item-action>
                    </v-list-item>


                </v-list>
            </v-card-text>
        </v-card>
    </v-col>
</v-row>
</template>

<script>
export default {
  name: 'AppsComponent',
};
</script>

<style>

</style>
