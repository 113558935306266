<template>
<v-flex xs12>
  <valid-commenter :valid_data="valid_data" v-if="granted"
  :loading="loading"
  :user="user"
  :pic="pic"
  :display_name="display_name">
  </valid-commenter>
  <div v-else>
    Vous ne disposez pas des droits suffisants pour consulter cet élément.
  </div>
</v-flex>

</template>

<script>
import ValidCommenter from '../components/ValidCommenter.vue';

export default {
  components: {
    ValidCommenter,
  },
  name: 'CommentValid',
  data() {
    return {
      valid_data: {},
      loading: true,
      granted: true,
      user: this.$store.state.userData.username,
      pic: this.$store.state.userData.settings.pic,
      display_name: (this.$store.state.userData.settings.display_name !== '') ? this.$store.state.userData.settings.display_name : this.$store.state.userData.username,
    };
  },
  mounted() {
    this.$http.get(`${this.$api_url}/validation/${this.$route.params.val_id}`).then((response) => {
      this.granted = response.data.granted;
      this.valid_data = response.data.validation;
      this.loading = false;
    });
  },
};
</script>

<style>

</style>
