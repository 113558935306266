<template>
<v-col ref="main_block" cols="12" v-resize="resize" >

<v-card ref="container" :loading="loading">
      <v-tooltip top="">
      <template v-slot:activator="{ on }">
   <v-btn v-on="on" @click="newComment"
                absolute
                dark
                fab
                right
                top
                color="primary"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
      </template>
      <span>Ajouter un commentaire</span>
      </v-tooltip>

    <v-card-title class="warning white--text">
    <v-tooltip bottom="">
      <template v-slot:activator="{ on }">

<a :href="$base_url+'/comments_docx/'+valid_id" download>
 <v-btn icon v-on="on" >
                <v-icon>mdi-download</v-icon>
              </v-btn>
</a></template><span>Télécharger les commentaires au format .docx</span></v-tooltip>
              <v-spacer></v-spacer>

        <v-checkbox
      v-model="checkbox"
      label="Pause à chaque commentaire"
    ></v-checkbox>

<v-spacer></v-spacer>
      <v-tooltip top>
      <template v-slot:activator="{ on }">
              <v-btn icon v-on="on"  @click="deselectComment">
                <v-icon>mdi-select-off</v-icon>
              </v-btn></template>Désélectionner les commentaires</v-tooltip>
              <v-spacer></v-spacer>
              <span>{{currentTime | timecode}}</span>
              <v-spacer></v-spacer>
               <v-tooltip top>
      <template v-slot:activator="{ on }">
              <v-btn icon v-on="on" @click="$emit('oneFrameBack')">
                <v-icon>mdi-step-backward</v-icon>
              </v-btn></template>Reculer d'une image</v-tooltip>
               <v-btn icon @click="playPlause">
                <v-icon v-if="!playing">mdi-play</v-icon>
                <v-icon v-else>mdi-pause</v-icon>
              </v-btn>
              <v-tooltip top>
      <template v-slot:activator="{ on }">
                <v-btn icon v-on="on" @click="$emit('oneFrameForward')">
                <v-icon>mdi-step-forward</v-icon>

              </v-btn></template>
              Avancer d'une image </v-tooltip>

    </v-card-title>
    <v-card-text>

            <div class="playhead" draggable="true"
             :style="{left: caluculatedPosition, height: height, botton:botton}"
             >
            </div>


<div ref="timeline"  @click="movePlayHead" style="pointer:cursor;">


    <user-comments :slectedComment.sync="currentComment"
     v-for="item in sortedComments" :key="item.time" :factor="factor"
     :comments="item" ></user-comments>


<v-divider></v-divider>
    </div></v-card-text></v-card>
</v-col>
</template>

<script>

import _ from 'lodash';
import moment from 'moment';
import UserComments from './UserComments.vue';


export default {
  name: 'PlayerTimeline',
  components: {
    UserComments,
  },

  props: {
    size: {
      type: Number,
      default: 0,
    },
    playing: {
      type: Boolean,
      default: false,
    },
    comments: {
      type: Array,
      default: () => [],
    },
    userSelectedComment: {
      type: Object,
      default() { return {}; },
    },
    valid_id: {
      type: Number,
    },
    user: {
      type: String,
    },
    pic: {
      type: String,
    },
    display_name: {
      type: String,
    },

  },

  watch: {
    currentComment(newValue) {
      this.$emit('update:userSelectedComment', newValue);
      if (this.checkbox === true) {
        this.$emit('pause');
      }
    },
    comments() {
      this.$nextTick(() => {
        this.adjustPlayHead(this.$refs.timeline);
      });
    },
  },
  methods: {
    deselectComment() {
      this.$emit('update:userSelectedComment', {});
      this.currentComment = {};
    },
    createNewComment(e) {
      const newComment = {
        active: false,
        comments: [

          {
            msg: e,
            user: this.user,
            pic: this.pic,
            editable: true,
            display_name: this.display_name,
            timestamp: moment().toString(),
          }],
        open_by: this.user,
        open_by_display: this.display_name,
        time: this.currentTime,

      };

      const commentCopy = _.clone(this.comments);
      commentCopy.push(newComment);
      this.$emit('update:comments', commentCopy);
      this.$emit('update:userSelectedComment', newComment);
      this.currentComment = newComment;
      this.$emit('playPause');
    },
    movePlayHead(e) {
      if (e.target.tagName === 'DIV') {
        const rect = e.target.getBoundingClientRect();
        const x = e.clientX - rect.left + 10; // x position within the element.
        this.$emit('position', ((x / this.$refs.timeline.clientWidth) * this.max) - 10);
      }
    },

    async newComment() {
      if (this.currentTime === 0) {
        return;
      }
      this.$emit('pause');
      const comment = await this.$dialog.prompt({
        text: `Commentaire à ${this.$filters.timecode(this.currentTime)}`,
        title: 'Votre commentaire',

      });
      if (comment === undefined || comment === false) {
        return;
      }
      this.createNewComment(comment);
    },
    setCurentTime(time) {
      this.currentTime = time;
    },
    playPlause() {
      this.$emit('playPause');
    },
    setDuration(duration) {
      this.max = duration;
      this.factor = this.$refs.timeline.clientWidth / this.max;
      this.adjustPlayHead(this.$refs.timeline);

      this.loading = false;
    },
    resize() {
      if (this.resized) {
        this.setDuration(this.max);
      }
      this.resized = true;
    },
    adjustPlayHead(timeline) {
      this.height = `${timeline.offsetHeight + 10}px`;
    },
  },
  data() {
    return {
      resized: false,
      height: '0px',
      checkbox: false,
      currentComment: null,
      factor: 1,
      currentTime: 0,
      botton: '',
      position: '0px',
      loading: true,
      max: -1,
      dialog: false,
    };
  },

  computed: {


    sortedComments() {
      return _.groupBy(this.comments, comment => comment.open_by);
    },
    caluculatedPosition() {
      const { timeline } = this.$refs;
      const time = this.currentTime;


      if (timeline) {
        const calc = this.factor * time;
        return `${calc}px`;
      }
      return '0px';
    },
    fixedCurrentTime: {
      get() {
        return this.currentTime;
      },
      set() {
      },
    },
  },
};
</script>

<style scopped>
.playhead {
  background: rgba(230, 6, 6, .5);
  float: left;
  position: absolute;
  width: 4px;
  color: #FFFFFF;
  cursor: pointer;
  /* Just for looks*/
  z-index: 14;
  margin-left: 12px;
}

</style>
