<template>
<v-card height="100%" :loading="loading" flat>
    <v-snackbar v-model="snackbar.show" :color="snackbar.color">
        {{snackbar.msg}}
    </v-snackbar>
    <v-img class="white--text align-end" max-height="200px" :src="$api_url + '/master_thumb/'
    +  master.id +'/' + $store.state.token +'.jpg?'+master.key">
        <v-card-title>Partage : {{master.name}}</v-card-title>
    </v-img>
    <v-card-text v-if="!hasLink" height="100%">
        <h3>Partager...</h3>
        <v-form>
            <v-text-field label="Nom du partage" required v-model="shareName"
            @change="error_message = ''" :error-messages="error_message"></v-text-field>
            <v-label>Durée du partage</v-label>
            <v-slider v-model="duration" :tick-labels="ticksLabels"
            :max="2" step="1" ticks="always" tick-size="4"></v-slider>
            <v-checkbox v-model="dl" label="Téléchargement autorisé"></v-checkbox>
        </v-form>
    </v-card-text>

    <v-card-text v-if="hasLink" class="white black--text">
        <div><b>Lien de téléchargement :</b><br>{{$base_url}}/#/masterShare/{{this.uuid}}</div>

        <div><b>Mot de passe : </b>{{this.passw}}</div>

    </v-card-text>

    <v-card-actions v-if="hasLink">
        <v-tooltip bottom>
            <template v-slot:activator="{on}">
                <v-btn color="accent" icon @click="hasLink=false" v-on="on">

                    <v-icon>
                        mdi-arrow-left</v-icon>
                </v-btn>
            </template><span>
                Retour</span></v-tooltip>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
            <template v-slot:activator="{ on }">
                <v-btn v-on="on" color="orange" icon @click="copy">
                    <v-icon>
                        mdi-content-copy</v-icon>
                </v-btn>
            </template>
            <span>Copier dans le presse papier</span></v-tooltip>

    </v-card-actions>

    <v-card-actions v-if="!hasLink">
        <v-spacer></v-spacer>

        <v-btn @click="generateLink" color="primary">Générer le lien</v-btn>
    </v-card-actions>

</v-card>
</template>

<script>
import moment from 'moment';

export default {
  name: 'ShareMasterWidget',
  mounted() {
    this.shareName = `${this.master.name}_${moment().locale('fr').format('LLL')}`;
  },
  methods: {
    copy() {
      navigator.clipboard.writeText(`Lien : ${this.$base_url}/#/masterShare/${this.uuid}\nMot de passe : ${this.passw}`)
        .then(() => {
          this.snackbar = {
            show: true,
            color: 'primary',
            msg: 'Lien copié dans le presse papier',
          };
        })
        .catch(() => {
          // This can happen if the user denies clipboard permissions:
          this.snackbar = {
            show: true,
            color: 'error',
            msg: 'Votre navigateur ne supporte pas la copie',
          };
        });
    },
    generateLink() {
      if (this.shareName === '') {
        this.error_message = 'Un nom est requis';
        return;
      }
      this.loading = true;
      this.$http.post(`${this.$api_url}/make_master_share`, {
        id: this.master.id,
        dl: this.dl,
        ln: this.duration,
        name: this.shareName,
      }).then(
        (response) => {
          console.log(response.data);
          this.loading = false;
          this.hasLink = true;
          this.uuid = response.data.uuid;
          this.passw = response.data.passw;
        },
      ).catch((e) => {
        this.snackbar = true;
        console.log(e);
        this.$emit('cancel');
      });
    },
  },
  data() {
    return {
      uuid: undefined,
      error_message: '',
      shareName: '',
      passw: undefined,
      duration: 0,
      hasLink: false,
      loading: false,
      snackbar: {
        show: false,
        color: 'sucess',
        msg: 'Lien copié dans le presse papier',
      },
      dl: true,
      ticksLabels: [
        '7 jours',
        '1 mois',
        'Toujours',
      ],
    };
  },
  props: {
    master: {
      type: Object,
      default: undefined,
    },
  },
};
</script>

<style>

</style>
