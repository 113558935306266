<template>
<v-container fluid>

    <v-row>

      <v-col cols="12">
<v-card :loading="loading" :elevation="0">
<v-card-title>Choisir une miniature</v-card-title>
  <v-card-text> <v-row
          align="center"
        >

          <v-card

            v-for="n in thumbs"
            :key="n.id"
            class="ma-2 pa-2 grey lighten-5"


            :loading="!n.loaded"
            @click="selected = n"
            :class="{'error' :selected === n}"

          >
           <v-img v-if="n.loaded" :src="n.src"></v-img>

          </v-card>
        </v-row>
  </v-card-text>
        <v-card-actions>
          <v-btn @click="getThumbs" color="accent"><v-icon>mdi-sync</v-icon> Recharger</v-btn>

          <v-spacer></v-spacer>
          <v-btn @click="postImage" color="success"
          :disabled="selected == undefined">
          <v-icon>mdi-camera</v-icon> Valider</v-btn>
        </v-card-actions>

</v-card>
      </v-col>

    </v-row>
</v-container>
</template>

<script>
export default {

  name: 'ThumbChanger',
  props: {
    prefix: {
      type: String,
      default: 'master',
    },
    master: {
      type: Object,
      default: undefined,

    },
  },
  data() {
    return {
      loading: false,
      selected: undefined,
      thumbs: [
        {
          id: Date.now() + Math.random(),
          src: '',
          loaded: false,
        },
        {
          id: Date.now() + Math.random(),
          src: '',
          loaded: false,
        },
        {
          id: Date.now() + Math.random(),
          src: '',
          loaded: false,
        },
        {
          id: Date.now() + Math.random(),
          src: '',
          loaded: false,
        },
        {
          id: Date.now() + Math.random(),
          src: '',
          loaded: false,
        },
        {
          id: Date.now() + Math.random(),
          src: '',
          loaded: false,
        },
      ],
    };
  },
  mounted() {
    this.getThumbs();
  },
  computed: {
    isActive(el) {
      if (el === this.selected) {
        return 'red';
      }
      return '';
    },
  },
  methods: {
    postImage() {
      this.loading = true;
      this.$http.post(`${this.$api_url}/${this.prefix}_generate_update/${this.master.id}`,
        { img: this.selected.src }).then(
        () => {
          this.loading = false;
          this.$set(this.master, 'key', this.master.key + 1);
          this.$emit('cancel');
        },
      );
    },

    getThumbs() {
      this.selected = undefined;
      this.thumbs.forEach((img) => {
        // eslint-disable-next-line no-param-reassign
        img.loaded = false;
      });
      this.thumbs.forEach((element) => {
        this.$http.get(`${this.$api_url}/${this.prefix}_generate/${this.master.id}`).then(
          (res) => {
            // eslint-disable-next-line no-param-reassign
            element.src = res.data.thumbs;
            // eslint-disable-next-line no-param-reassign
            element.loaded = true;
          },
        );
      });
    },
  },
};
</script>

<style>

</style>
