<template>
<v-container >
<v-card :loading="loading">

    <v-card-title>Partages</v-card-title>
      <v-card-text>
<v-tabs v-model="current" @change="change">
    <v-tab href="#tab-2">Masters</v-tab>
    <v-tab href="#tab-3">Validations</v-tab>
    <v-tab href="#tab-4">Projets</v-tab>
     <v-tab-item value="tab-2"><master-share-table
     :masterShares="shares.master_shares"></master-share-table></v-tab-item>
       <v-tab-item value="tab-3"><valid-share-table
        :masterShares="shares.valid_shares"></valid-share-table></v-tab-item>
       <v-tab-item value="tab-4">
         <project-share-table
         :masterShares='shares.project_shares'></project-share-table>
       </v-tab-item>
  </v-tabs>


  </v-card-text>
</v-card>
</v-container>
</template>

<script>
import _ from 'lodash';
import MasterShareTable from '../components/Shares/MasterShareTable.vue';
import ValidShareTable from '../components/Shares/ValidShareTable.vue';
import ProjectShareTable from '../components/Shares/ProjectShareTable.vue';

export default {
  name: 'Shares',
  data() {
    return {
      shares: {},
      current: 0,
      loading: true,
      mapper: {
        'tab-2': '/shares/masters',
        'tab-3': '/shares/validations',
        'tab-4': '/shares/projets',
      },
    };
  },
  components: {
    MasterShareTable,
    ValidShareTable,
    ProjectShareTable,
  },
  methods: {
    change() {
      this.$router.push(this.mapper[this.current]);
    },
  },
  mounted() {
    this.current = (_.invert(this.mapper))[this.$route.path];


    this.$http.get(`${this.$api_url}/shares`).then((res) => {
      this.loading = false;
      this.shares = res.data;
    });
  },
};
</script>

<style>

</style>
