var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-dialog',{model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[(_vm.dialog)?_c('clip-edit-dialog',{attrs:{"readOnly":_vm.readOnly,"canNext":_vm.canNext,"canPrevious":_vm.canPrevious,"currentClip":_vm.currentClip,"selections":_vm.selections},on:{"cancel":function($event){return _vm.closeDialog()},"update:currentClip":function($event){_vm.currentClip=$event},"update:current-clip":function($event){_vm.currentClip=$event},"previous":_vm.previous,"next":_vm.next}}):_vm._e()],1),_c('v-col',[_c('v-card',{attrs:{"loading":_vm.child_loading}},[(!_vm.loading)?_c('v-card-title',[_vm._v(_vm._s(_vm.currentCard.name))]):_vm._e(),_c('v-card-text',[(!_vm.loading)?_c('v-data-table',{attrs:{"color":"accent","items":_vm.currentCard.clips,"headers":_vm.headers},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [(_vm.$vuetify.breakpoint.xs)?_c('v-avatar',[(!_vm.readOnly)?_c('img',{attrs:{"src":_vm.$api_url+'/asset_th/'+item.id}}):_c('img',{attrs:{"src":item.thumb}})]):_c('div',[(!_vm.readOnly)?_c('img',{attrs:{"src":_vm.$api_url+'/asset_th/'+item.id}}):_c('img',{attrs:{"src":item.thumb}})])]}},{key:"item.tags",fn:function(ref){
var item = ref.item;
return _vm._l((item.tags),function(t){return _c('v-chip',{key:t.id},[_vm._v(_vm._s(t.text))])})}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){_vm.currentClip=[item]}}},on),[_c('v-icon',[_vm._v("mdi-eye")])],1)]}}],null,true)},[_c('span',[_vm._v("Détails")])])]}}],null,false,3718856848),model:{value:(_vm.currentClip),callback:function ($$v) {_vm.currentClip=$$v},expression:"currentClip"}}):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }