<template>
  <div class="text-center">
    <v-menu offset-y>
      <template v-slot:activator="{ on }">
        <v-btn
          text
          v-on="on"
        >
          {{ username }}
          <v-avatar v-if="$store.state.userData.settings.pic">
            <v-img :src="$store.state.userData.settings.pic" />
          </v-avatar>
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          v-for="(item, index) in items"
          :key="index"
          @click="menuAction(index)"
        >
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>


<script>
export default {
  name: 'UserMenu',
  computed: {
    username() {
      return (this.$store.state.userData.settings.display_name !== '') ? this.$store.state.userData.settings.display_name : this.$store.state.userData.username;
    },
  },
  methods: {
    menuAction(e) {
      if (e === 1) {
        this.$store.commit('logout');
      }
      if (e === 0) {
        this.$router.push('/profile');
      }
    },
  },
  data() {
    return {
      items: [{

        title: 'Mon profile',

      }, {
        title: 'Se déconnecter',
      }],
    };
  },
};
</script>

<style lang="scss" scoped>

</style>
