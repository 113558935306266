<template>
<v-card :loading='loading'>
    <v-card-title>Espace Personnel</v-card-title>
    <v-card-text>
    <v-tabs
      flat
      dark
      centered
      grow
    >
      <v-tab
        v-for="i in tree"
        :key="i.name"
        :href="`#tab-${i.name}`"
      >
        {{ i.name }}
      </v-tab>

      <v-tab-item
        v-for="i in tree"
        :key="i.name"
        :value="'tab-' + i.name"
      >
        <v-card
          flat
          tile
        >
          <v-card-text>
    <v-row><v-spacer></v-spacer>&nbsp;
    <v-btn  @click="remove_project_space(i)" text v-if="i.deletable" color="red">
        <v-icon>mdi-delete</v-icon>Supprimer</v-btn></v-row>
<mount-table :project="i.nodes" :space="i.name"></mount-table>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs>
</v-card-text>
</v-card>
</template>

<script>
import MountTable from '../components/MountTable.vue';

export default {
  name: 'PersoSpace',
  components: {
    MountTable,
  },
  data() {
    return {
      loading: true,
      tree: {},

    };
  },
  methods: {
    remove_project_space(item) {
      this.$confirm('Voulez-vous vraiment supprimer le projet ?').then((res) => {
        if (res === true) {
          this.loading = true;
          this.$http.post(`${this.$api_url}/remove_project`, { space: item.name }).then(() => {
            this.$delete(this.tree, this.tree.indexOf(item));
            this.loading = false;
          });
        }
      });
    },

    get_perso_data() {
      this.$http.get(`${this.$api_url}/perso`).then((res) => {
        this.tree = res.data;
        this.loading = false;
      });
    },
  },
  mounted() {
    this.get_perso_data();
  },
};
</script>

<style>

</style>
