<template>
<v-card flat :loading="loading">
    <v-snackbar v-model="snackbar.show" :color="snackbar.color">
        {{snackbar.msg}}
    </v-snackbar>
    <v-card-text v-if="!hasLink" height="100%">
        <h3>Partager...</h3>
        <v-form ref="form">
          <v-text-field label="Nom du partage"
          required v-model="shareName"
                  @change="error_message3 = ''"
           :error-messages="error_message3"></v-text-field>
            <v-label>Durée du partage</v-label>
            <v-slider v-model="duration" :tick-labels="ticksLabels"
            :max="2" step="1" ticks="always" tick-size="4"></v-slider>
            <v-checkbox v-model="comment" label="Mode commentaire"></v-checkbox>
            <v-text-field :disabled="!comment"
            @keydown="error_message=''"
             :error-messages="error_message"
             label="Nom à affcher sur les commentaires"
             v-model="clientName"></v-text-field>
        <v-select v-if="!loading"
        @change="error_message2 = ''"
        :error-messages = "error_message2"
            v-model="selectedUsers"
            :items="users"
            filled
            chips
            label="Personnes à prévenir à l'ouverture du lien"
            multiple
          ></v-select>
        </v-form>
    </v-card-text>
    <v-img v-if="hasLink" class="white--text align-end"
    max-height="200px" :src="$api_url + '/valid_th/'+ valid.uuid +'.jpg'">
        <v-card-title v-if="hasLink">Partage : {{valid.name}}</v-card-title>
    </v-img>

    <v-card-text v-if="hasLink" class="white black--text">
        <div><b>Lien de téléchargement :</b><br>{{$base_url}}/#/validShare/{{uuid}}</div>

        <div><b>Mot de passe : </b>{{passw}}</div>

    </v-card-text>

    <v-card-actions v-if="hasLink">
       <v-btn
        color="accent"
        icon
        @click="hasLink=false"
      >
      <v-icon>
        mdi-arrow-left</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-btn color="orange" icon @click="copy">
            <v-icon>
                mdi-content-copy</v-icon>
        </v-btn>

    </v-card-actions>

    <v-card-actions v-if="!hasLink">
        <v-spacer></v-spacer>

        <v-btn class="success" @click="generateLink">Générer le lien</v-btn>
    </v-card-actions>

</v-card>
</template>

<script>
import moment from 'moment';

export default {
  name: 'ValidShare',
  props: {

    valid: {
      type: Object,
      default: () => {},
    },
  },
  mounted() {
    this.$http.get(`${this.$api_url}/users`).then((res) => {
      this.users = res.data;
      this.loading = false;
      this.selectedUsers = [this.$store.state.userData.id];
      this.shareName = `${this.valid.name}_${moment().locale('fr').format('LLL')}`;
    });
  },
  methods: {
    copy() {
      navigator.clipboard.writeText(`Lien : ${this.$base_url}/#/validShare/${this.uuid}\nMot de passe : ${this.passw}`)
        .then(() => {
          this.snackbar = { show: true, color: 'primary', msg: 'Lien copié dans le presse papier' };
        })
        .catch(() => {
          // This can happen if the user denies clipboard permissions:
          this.snackbar = { show: true, color: 'error', msg: 'Votre navigateur ne supporte pas la copie' };
        });
    },
    generateLink() {
      if (this.comment && this.clientName === '') {
        this.error_message = 'Le nom du client est requis pour pouvoir commenter';
        return;
      }
      if (this.selectedUsers.length === 0) {
        this.error_message2 = 'Au moins un utilisateur doir être sélectionné.';
        return;
      }
      if (this.shareName === '') {
        this.error_message3 = 'Un nom est requis';
        return;
      }
      this.loading = true;
      this.$http.post(`${this.$api_url}/createValidShare`, {
        id: this.valid.id,
        warn: this.selectedUsers,
        duration: this.duration,
        comment: this.comment,
        clientName: this.clientName,
        name: this.shareName,
      }).then((res) => {
        this.hasLink = true;
        this.uuid = res.data.uuid;
        this.passw = res.data.passw;
        this.loading = false;
      });
    },
  },
  data() {
    return {
      shareName: '',
      error_message3: '',
      error_message: '',
      error_message2: '',
      users: [],
      selectedUsers: [],
      uuid: undefined,
      passw: undefined,
      clientName: '',
      duration: 0,
      hasLink: false,
      loading: true,
      snackbar: {
        show: false,
        color: 'sucess',
        msg: 'Lien copié dans le presse papier',
      },
      comment: false,
      ticksLabels: [
        '7 jours',
        '1 mois',
        'Toujours',
      ],
    };
  },
};
</script>

<style>

</style>
