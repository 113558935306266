<template>
<v-container class="fill-height" fluid>

    <v-row align="center" justify="center">

        <v-col cols="12" sm="8" md="4">

                <v-card class="elevation-12" :loading="loading">
                  <v-card-title>
                    <v-img  :src="require('../assets/my_logo.png')"
                    height="90px" width="90px"></v-img>
                  </v-card-title>
                    <v-toolbar dark flat>
                        <v-toolbar-title>Changer votre mot de passe</v-toolbar-title>
                        <div class="flex-grow-1"></div>

                    </v-toolbar>
                    <v-card-text>
                        <v-form ref="form">
                            <v-text-field @keydown.enter.prevent="validate"
                            :rules="[emailRules[0],emailRules[1]]"
                            type="password" v-model="pass1" hint="At least 8 characters"
                            prepend-icon="mdi-key"
                            label="Entrer votre mot de passe"></v-text-field>
                            <v-text-field @keydown.enter.prevent="validate"
                            :rules="[emailRules[0],passwordConfirmationRule]"
                            type="password" v-model="pass2" hint="At least 8 characters"
                            prepend-icon="mdi-key"
                            label="Entrer votre mot de passe"></v-text-field>
                        </v-form>
                        <v-card-actions>

                           <v-spacer></v-spacer>
                            <v-btn @click="validate" color="red">Réinitialiser</v-btn>
                        </v-card-actions>
                    </v-card-text>
                </v-card>

        </v-col>
    </v-row>
</v-container>
</template>

<script>


export default {
  name: 'ResetPassword',
  data: () => ({
    loading: false,
    pass1: '',
    pass2: '',
    emailRules: [
      v => !!v || 'Mot de passe requis',
      v => v.length > 7 || 'Mot de passe trop court',

    ],

  }),
  computed: {
    passwordConfirmationRule() {
      return () => (this.pass1 === this.pass2) || 'Password must match';
    },
  },
  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        this.$http.post(`${this.$api_url}/reset_passw`,
          { key: this.$route.params.reset_key, passw: this.pass1 }).then((res) => {
          this.loading = false;
          if (res.data.ok === true) {
            this.$router.push('/');
          } else {
            this.$dialog.notify.error('Impossible de réinitialiser le mot de passe.', {
              position: 'top-right',
              timeout: 5000,
            });
          }
        });
      }
    },
  },
};
</script>
