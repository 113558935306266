<template>
<v-container class="fill-height" fluid>
  <v-snackbar
  color="red"
      v-model="snackbar"
      :multi-line=true
    >
      <h4>Impossible de se connecter.&nbsp; </h4>
   {{error_code}}
      <v-btn

        text
        @click="snackbar = false"
      >
        Fermer
      </v-btn>
    </v-snackbar>
    <v-row align="center" justify="center">

        <v-col cols="12" sm="8" md="4">
                <v-card class="elevation-12" :loading="loading">
                  <v-card-title>
                  </v-card-title>
                    <v-toolbar dark flat>
                      <v-spacer></v-spacer>
                                          <v-img  :src="require('@/assets/G.png')"
                                           style="max-height: 30px; max-width: 30px;"></v-img>

                        <v-toolbar-title>Mam Galaxie - Accès réservé</v-toolbar-title>
                        <div class="flex-grow-1"></div>

                    </v-toolbar>
                    <v-card-text>
                        <v-form ref="form">
                            <v-text-field :rules="[v => !!v || 'Nom requis']"
                              v-model="username" required label="Login"
                              name="login" prepend-icon="mdi-account"
                              type="text"
                              @keypress.native.enter="validate">
                              </v-text-field>
                            <v-text-field :rules="[v => !!v || 'Mot de passe requis']"
                              v-model="password" required id="password"
                              label="Password" name="password"
                              prepend-icon="mdi-key"
                              type="password"
                              @keypress.native.enter="validate">

                              </v-text-field>
                        </v-form>
                        <v-card-actions>
                          <router-link to="/forgetPassw">Mot de passe oublié ?</router-link>

                           <v-spacer></v-spacer>
                            <v-btn color="primary" @click="validate">Login</v-btn>
                        </v-card-actions>
                    </v-card-text>
                </v-card>
        </v-col>
    </v-row>
</v-container>
</template>

<script>

export default {
  name: 'LoginForm',
  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        this.$store.dispatch('login', {
          username: this.username,
          password: this.password,
        })
          .then(() => {
            this.$vuetify.theme.dark = this.$store.state.userData.settings.dark;
            this.loading = false;
            if (this.$route.query.then) {
              this.$router.push(decodeURIComponent(this.$route.query.then));
            } else {
              this.$router.push('/');
            }
          })
          .catch((e) => {
            if (e.toString().includes(401)) {
              this.error_code = "Mauvais nom d'utilisateur ou mot de passe";
            } else {
              this.error_code = e;
            }
            this.snackbar = true;
            this.loading = false;
          });
      }
    },
  },
  data: () => ({
    valid: false,
    loading: false,
    username: '',
    password: '',
    snackbar: false,
    error_code: null,
  }),
};
</script>
