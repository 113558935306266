<template>
<v-card>
  <v-card-title>Accès au projets</v-card-title>
  <v-card-text>

        <collection-switch v-model="collectionGroup"></collection-switch>
        <v-row>
          <v-col v-if="updating">
           <v-progress-circular color="primary" indeterminate ></v-progress-circular>
          Actualisation...
          </v-col>
            <v-spacer></v-spacer>
            <v-col cols="6">
                <v-text-field v-model="search"
                append-icon="mdi-magnify" label="Chercher..."
                clearable="" single-line  hide-details></v-text-field>
            </v-col>
        </v-row>
  <v-data-table
    :headers="headers"
    :items="projects"
    :items-per-page="5"
    :loading="loading"
  >
   <template v-slot:item.name="{ item }">
                <b><text-highlight :queries="light">{{item.name}}</text-highlight></b>
             </template>
<template v-slot:item.group="{ item }"></template>
<template v-slot:item.collection="{ item }"></template>
  <template v-slot:item.id="{ item }">
    <v-avatar>
      <v-img :eager="true"  :src="$api_url + '/project_th/'
      +item.id" max-height="60px"></v-img></v-avatar>
    </template>
     <template v-slot:item.len_cards="{ item }">
     <div class="text-center">
  <v-avatar color="primary">{{item.len_cards}}</v-avatar>
  </div>
    </template>
  <template v-slot:item.action="{ item }">
    <v-tooltip bottom="">
    <template v-slot:activator="{ on }">

    <v-btn icon v-on="on" @click="editItem(item)">
      <v-icon>
        mdi-pencil
      </v-icon></v-btn>
    </template>
    <span>Renommer le projet</span>
    </v-tooltip>
      <v-tooltip bottom="">
    <template v-slot:activator="{ on }">

<v-btn  v-on="on" icon :to="{name: 'project_detail', params: {project_id:item.id}}">
      <v-icon>
        mdi-eye
      </v-icon></v-btn>
    </template>
    <span>Voir projet</span>
    </v-tooltip>
    </template>
  </v-data-table></v-card-text>
</v-card>
</template>

<script>
import _ from 'lodash';
import CollectionSwitch from '../components/CollectionSwitch.vue';

export default {
  nome: 'Projects',
  components: {
    CollectionSwitch,
  },
  computed: {
    light() {
      if (this.search) {
        return [this.search];
      }
      return '';
    },
    computedHeaders() {
      return this.headers.filter(h => h.value !== 'group' && h.value !== 'collection');
    },
  },
  data() {
    return {
      updating: false,
      collectionGroup: {
        currentCol: undefined,
        currentGroup: undefined,
      },
      search: '',
      headers: [{
        text: 'Image',
        value: 'id',
      }, {
        text: 'Nom',
        value: 'name',
        filter: this.nameFilter,
      },
      {
        text: 'Rangé dans...',
        value: 'path',

      },
      {
        text: 'Cartes',
        value: 'len_cards',
      },
      {
        text: '',
        value: 'collection',
        filter: this.collectionFilter,
        sortable: false,

      },
      {
        text: '',
        value: 'group',
        filter: this.groupFilter,
        sortable: false,

      },

      { text: 'Actions', value: 'action', sortable: false },

      ],
      loading: true,
      projects: [],
      itemsPerPage: 4,
      itemsPerPageOptions: [4, 8, 16],
    };
  },
  watch: {
    search(value) {
      const quer = _.clone(this.$route.query);
      if (value === null) {
        quer.filter = undefined;
      } else { quer.filter = value; }

      this.$router.push({ path: this.$route.path, query: quer });
    },
  },
  methods: {
    groupFilter(value) {
      if (this.collectionGroup.currentGroup === undefined) {
        return true;
      }
      return value === this.collectionGroup.currentGroup;
    },
    collectionFilter(value) {
      if (this.collectionGroup.currentCol === undefined) {
        return true;
      }
      return value === this.collectionGroup.currentCol;
    },
    nameFilter(value) {
      // If this filter has no value we just skip the entire filter.
      if (!this.search) {
        return true;
      }
      // Check if the current loop value (The dessert name)
      // partially contains the searched word.
      return value.toLowerCase().includes(this.search.toLowerCase());
    },

    async editItem(item) {
      const person = await this.$dialog.prompt({
        text: 'Renommer',
        title: 'Nom du projet',
        value: item.name,
      });
      if (person !== false) {
        if (person !== null && person !== '') {
          this.$http.post(`${this.$api_url}/rename_project`, { project: item.id, name: person }).then(() => {
            // eslint-disable-next-line no-param-reassign
            item.name = person;
          });
        }
      }
    },
  },
  mounted() {
    this.search = this.$route.query.filter;
    let tempProjects = localStorage.getItem('projects');
    if (tempProjects != null) {
      this.projects = JSON.parse(tempProjects);
      this.loading = false;
    } else {
      localStorage.setItem('projects', JSON.stringify([]));
      tempProjects = localStorage.getItem('projects');
    }
    this.updating = true;
    this.$http.get(`${this.$api_url}/projects`).then((res) => {
      this.loading = false;
      localStorage.setItem('projects', JSON.stringify(res.data));
      if (!_.isEqual(JSON.parse(tempProjects).sort(), res.data.sort())) {
        this.projects = res.data;
      }
      this.updating = false;
    });
  },
};
</script>

<style>

</style>
