var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-dialog',{attrs:{"width":"80%"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[(_vm.selected[0] !== undefined)?_c('valid-details',{ref:"validDetail",attrs:{"valid":_vm.selected[0]},on:{"reload":_vm.loadValid,"update:valid":function($event){return _vm.$set(_vm.selected, 0, $event)},"cancel":function($event){_vm.selected=[]}}}):_vm._e()],1),_c('v-card-title',[_vm._v("Validations")]),(this.$store.state.userData.validation)?_c('v-card-text',[_c('collection-switch',{model:{value:(_vm.collectionGroup),callback:function ($$v) {_vm.collectionGroup=$$v},expression:"collectionGroup"}}),_c('v-row',[_c('v-spacer'),(_vm.updating)?_c('v-col',[_c('v-progress-circular',{attrs:{"color":"primary","indeterminate":""}}),_vm._v(" Actualisation... ")],1):_vm._e(),_vm._v(" "),_c('v-spacer'),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Chercher...","clearable":"","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.valids,"items-per-page":_vm.itemPerPage,"page":_vm.page,"search":_vm.search,"loading":_vm.loading},on:{"update:itemsPerPage":function($event){_vm.itemPerPage=$event},"update:items-per-page":function($event){_vm.itemPerPage=$event},"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('b',[_c('text-highlight',{attrs:{"queries":_vm.light}},[_vm._v(_vm._s(item.name))])],1)]}},{key:"item.comment",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.light}},[_vm._v(_vm._s(item.comment))])]}},{key:"item.group",fn:function(ref){
var item = ref.item;
return undefined}},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',[_c('v-img',{attrs:{"aspect-ratio":16/9,"eager":true,"src":_vm.$api_url +'/valid_th/' + item.uuid +'.jpg'+'?'+item.key,"width":"100px"}})],1)]}},{key:"item.collection",fn:function(ref){
var item = ref.item;
return undefined}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-row',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){_vm.selected = [item]}}},on),[_c('v-icon',[_vm._v(" mdi-eye ")])],1)]}}],null,true)},[_c('span',[_vm._v("Voir les détails")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-badge',{scopedSlots:_vm._u([{key:"badge",fn:function(){return [_vm._v(_vm._s(item.comments))]},proxy:true}],null,true)},[_c('v-btn',_vm._g({attrs:{"icon":"","to":'/validation/'+item.id+'/edit'}},on),[_c('v-icon',[_vm._v(" mdi-message ")])],1)],1)]}}],null,true)},[_c('span',[_vm._v("Commenter")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.shareValid(item)}}},on),[_c('v-icon',[_vm._v(" mdi-share ")])],1)]}}],null,true)},[_c('span',[_vm._v("Partager")])])],1)]}}],null,false,1874599893)})],1):_vm._e(),(!this.$store.state.userData.validation)?_c('v-card-text',[_vm._v(" Votre compte ne vous permet pas de gérer les validations ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }