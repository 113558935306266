var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_vm._v("Accès au projets")]),_c('v-card-text',[_c('collection-switch',{model:{value:(_vm.collectionGroup),callback:function ($$v) {_vm.collectionGroup=$$v},expression:"collectionGroup"}}),_c('v-row',[(_vm.updating)?_c('v-col',[_c('v-progress-circular',{attrs:{"color":"primary","indeterminate":""}}),_vm._v(" Actualisation... ")],1):_vm._e(),_c('v-spacer'),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Chercher...","clearable":"","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.projects,"items-per-page":5,"loading":_vm.loading},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('b',[_c('text-highlight',{attrs:{"queries":_vm.light}},[_vm._v(_vm._s(item.name))])],1)]}},{key:"item.group",fn:function(ref){
var item = ref.item;
return undefined}},{key:"item.collection",fn:function(ref){
var item = ref.item;
return undefined}},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',[_c('v-img',{attrs:{"eager":true,"src":_vm.$api_url + '/project_th/'
      +item.id,"max-height":"60px"}})],1)]}},{key:"item.len_cards",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"text-center"},[_c('v-avatar',{attrs:{"color":"primary"}},[_vm._v(_vm._s(item.len_cards))])],1)]}},{key:"item.action",fn:function(ref){
      var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.editItem(item)}}},on),[_c('v-icon',[_vm._v(" mdi-pencil ")])],1)]}}],null,true)},[_c('span',[_vm._v("Renommer le projet")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","to":{name: 'project_detail', params: {project_id:item.id}}}},on),[_c('v-icon',[_vm._v(" mdi-eye ")])],1)]}}],null,true)},[_c('span',[_vm._v("Voir projet")])])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }