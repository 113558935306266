<template>
<v-container>
    <v-row v-if="!locked">
            <v-col cols="10">
            <h1>{{project.name}}</h1>
             <v-breadcrumbs :items="ariane" divider=">"></v-breadcrumbs>
        </v-col>
        <share-project-component :currentProject="project"></share-project-component>

    </v-row>
    <v-row  v-if="!locked">

        <v-col>

            <v-row>
                <v-col>
                    <v-card :loading="loading" height="100%">
                        <v-card-title> Cartes </v-card-title>
                        <v-card-text>

                            <v-select :items="cards" v-model="currentCardId"></v-select>

                            <v-divider></v-divider>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <download-component :currentCard='currentCard[0].id'
                            :cards='project.cards' :projectName="project.name"
                            v-if="currentCard.length > 0"></download-component>
                            <mount-component :currentCard='currentCard[0]'
                            :cards='project.cards' v-if="currentCard.length > 0"></mount-component>
                            <card-info :currentCard='currentCard[0]'
                             v-if="currentCard.length > 0"></card-info>
                        </v-card-actions>
                    </v-card>
                </v-col>
                <v-col>
                    <comment-editor v-if="currentCard.length > 0"
                      :currentCard="currentCard[0]"></comment-editor>
                </v-col>

            </v-row>

            <clip-table ref="clipTable" :currentClipId.sync="currentClipId"
            :selections="selections" :loading="currentCard.length < 0 === undefined"
            :currentCard="currentCard[0]" v-if="currentCard.length > 0">
            </clip-table>

        </v-col>

    </v-row>
    <v-row v-if="locked">
      Vous ne disposez pas des droits suffisants pour voir le projet
    </v-row>
</v-container>
</template>

<script>
import CommentEditor from '../components/CommentEditor.vue';
import ShareProjectComponent from '../components/project/ShareProjectComponent.vue';
import ClipTable from '../components/clips/ClipTable.vue';
import MountComponent from '../components/project/MountComponent.vue';
import DownloadComponent from '../components/project/DownloadComponent.vue';
import CardInfo from '../components/project/CardInfo.vue';

export default {
  name: 'ProjectDetail',
  components: {
    CommentEditor,
    ShareProjectComponent,
    ClipTable,
    MountComponent,
    DownloadComponent,
    CardInfo,
  },
  data() {
    return {
      loading: true,
      currentCardId: undefined,
      currentClipId: null,
      project: {},
      selections: {},
      locked: false,
    };
  },
  mounted() {
    this.$http.get(`${this.$api_url}/cards/${this.$route.params.project_id}`).then((res) => {
      if (res.data.granted === true) {
        this.project = res.data.project;
        this.locked = false;
      } else {
        this.locked = true;
      }

      if (this.$route.query.card === undefined) {
        this.currentCardId = res.data.project.cards[0].id;
      } else if (this.$route.query.clip === undefined) {
        this.currentCardId = parseInt(this.$route.query.card, 10);
      } else {
        const tempClipId = parseInt(this.$route.query.clip, 10);
        // this.currentCardId = parseInt(this.$route.query.card, 10);
        // this.currentClipId = tempClipId;

        this.currentCardId = parseInt(this.$route.query.card, 10);
        this.$nextTick(() => {
          this.currentClipId = tempClipId;
          this.$nextTick(() => {
            const clp = this.project.cards
              .filter(e => e.id === this.currentCardId)[0].clips
              .filter(b => b.id === this.currentClipId);
            this.$set(this.$refs.clipTable, 'currentClip', clp);
          });
        });
      }
      this.selections = res.data.selections;
      this.loading = false;
    });
  },
  methods: {
    change_card(card, clip) {
      if (clip === undefined) {
        this.$router.push({ query: { card } },
          () => { });
      } else {
        this.$router.push({ query: { card, clip } },
          () => { });
      }
    },

  },
  watch: {
    currentClipId(newValue) {
      this.change_card(this.$route.query.card, newValue);
    },

    currentCardId(value) {
      this.change_card(value, undefined);
    },
  },
  computed: {
    currentCard() {
      if (!this.loading) {
        return this.project.cards.filter(e => e.id === this.currentCardId);
      }
      return [];
    },
    cards() {
      if (!this.loading) {
        const res = [];
        this.project.cards.forEach((e) => {
          res.push({
            text: e.name,
            value: e.id,
          });
        });
        return res;
      }

      return undefined;
    },
    ariane() {
      if (this.loading) {
        return [];
      }
      return [{
        text: this.project.collection,
      }, {
        text: this.project.group,
      }];
    },
  },
};
</script>
