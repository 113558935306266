var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.resize),expression:"resize"}],ref:"main_block",attrs:{"cols":"12"}},[_c('v-card',{ref:"container",attrs:{"loading":_vm.loading}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"absolute":"","dark":"","fab":"","right":"","top":"","color":"primary"},on:{"click":_vm.newComment}},on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}])},[_c('span',[_vm._v("Ajouter un commentaire")])]),_c('v-card-title',{staticClass:"warning white--text"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('a',{attrs:{"href":_vm.$base_url+'/comments_docx/'+_vm.valid_id,"download":""}},[_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("mdi-download")])],1)],1)]}}])},[_c('span',[_vm._v("Télécharger les commentaires au format .docx")])]),_c('v-spacer'),_c('v-checkbox',{attrs:{"label":"Pause à chaque commentaire"},model:{value:(_vm.checkbox),callback:function ($$v) {_vm.checkbox=$$v},expression:"checkbox"}}),_c('v-spacer'),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":_vm.deselectComment}},on),[_c('v-icon',[_vm._v("mdi-select-off")])],1)]}}])},[_vm._v("Désélectionner les commentaires")]),_c('v-spacer'),_c('span',[_vm._v(_vm._s(_vm._f("timecode")(_vm.currentTime)))]),_c('v-spacer'),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('oneFrameBack')}}},on),[_c('v-icon',[_vm._v("mdi-step-backward")])],1)]}}])},[_vm._v("Reculer d'une image")]),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.playPlause}},[(!_vm.playing)?_c('v-icon',[_vm._v("mdi-play")]):_c('v-icon',[_vm._v("mdi-pause")])],1),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('oneFrameForward')}}},on),[_c('v-icon',[_vm._v("mdi-step-forward")])],1)]}}])},[_vm._v(" Avancer d'une image ")])],1),_c('v-card-text',[_c('div',{staticClass:"playhead",style:({left: _vm.caluculatedPosition, height: _vm.height, botton:_vm.botton}),attrs:{"draggable":"true"}}),_c('div',{ref:"timeline",staticStyle:{"pointer":"cursor"},on:{"click":_vm.movePlayHead}},[_vm._l((_vm.sortedComments),function(item){return _c('user-comments',{key:item.time,attrs:{"slectedComment":_vm.currentComment,"factor":_vm.factor,"comments":item},on:{"update:slectedComment":function($event){_vm.currentComment=$event},"update:slected-comment":function($event){_vm.currentComment=$event}}})}),_c('v-divider')],2)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }