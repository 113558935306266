<template>
<v-row>
   <v-dialog
      v-model="dialog"
      max-width="290"
    >
      <v-card v-if='currentShare'>
        <v-card-title class="headline">Etendre la durée de
          {{currentShare.master.name}}</v-card-title>
        <v-card-text>
Choisir la durée de partage suppélemtaire :
            <v-slider v-model="duration"
            :tick-labels="ticksLabels" :max="1"
            step="1" ticks="always" tick-size="4"></v-slider>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="dialog = false"
          >
            Annuler
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="grantExtention"
          >
            Etendre
          </v-btn>
        </v-card-actions>
      </v-card>
   </v-dialog>

<v-flex md12 style="overflow: auto">
    <v-data-table :loading="loading"
    :items="ordered_shares" :headers="headers" :search="searchTerm">
    <template v-slot:body.prepend="{ headers }">
<tr class="secondary">
          <td :colspan="headers.length/2">
             <v-btn color="red" @click="purge_link">Supprimer tous les liens périmés</v-btn>
          </td>
          <td :colspan="headers.length/2">
          <v-text-field v-model="searchTerm"   append-icon="mdi-magnify" label="Chercher..."
                clearable="" single-line  hide-details ></v-text-field>
          </td>
        </tr>
    </template>
<template v-slot:item.expires="{ item }">
{{ item.expires | date }}
</template>
        <template v-slot:item.expired="{ item }">

            <v-avatar v-if="item.expired == true" color="red">Périmé</v-avatar>
            <v-avatar v-else color="green">Actif</v-avatar>
        </template>
        <template v-slot:item.master.id="{ item }">
            <v-avatar>
                <v-img :eager="true" :aspect-ratio="16/9"
                :src="$api_url + '/master_thumb/' +
                item.master.id +'/' + $store.state.token +'.jpg'+'?'+item.master.key"
                 width="100px" />
            </v-avatar>
        </template>
         <template v-slot:item.actions="{ item }">
<v-btn icon @click="deleteShare(item)"><v-icon>mdi-delete</v-icon></v-btn>
<v-tooltip bottom>
      <template v-slot:activator="{ on }">
       <v-btn  @click.prevent="extendDialog(item)" v-on="on"
       icon v-if="item.expires !== 'Jamais'"><v-icon>mdi-lock-reset</v-icon></v-btn>

      </template>
      <span>Etendre la durée</span>
    </v-tooltip>
        </template>
    </v-data-table></v-flex></v-row>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';


export default {
  name: 'MasterShareTable',
  props: {
    masterShares: {
      type: Array,
      default: () => [],
    },

  },
  methods: {
    grantExtention() {
      this.loading = true;
      this.$http.post(`${this.$api_url}/update_master_share`, {
        id: this.currentShare.id,
        ext: this.duration,
      }).then(() => {
        this.loading = false;
        if (this.duration === 0) {
          this.currentShare.expired = false;
          this.currentShare.expires = moment().add(7, 'days');
        } else {
          this.currentShare.expired = false;
          this.currentShare.expires = moment().add(30, 'days');
        }
      });
      this.dialog = false;
    },
    extendDialog(item) {
      this.currentShare = item;
      this.dialog = true;
    },
    deleteShare(item) {
      this.$confirm("Voulez vous supprimer le partage ? Il ne sera plus possible d'y accéder.").then((e) => {
        if (e === true) {
          this.loading = true;
          this.$http.post(`${this.$api_url}/delete_master_share`, { indexes: [item.id] }).then(() => {
            this.$delete(this.masterShares, this.masterShares.findIndex(b => b === item));
            this.loading = false;
          });
        }
      });
    },
    purge_link() {
      this.$confirm("Voulez vous supprimer les partages de masters périmés ? Il ne sera plus possible d'y accéder.").then((e) => {
        if (e === true) {
          this.loading = true;
          const a = _.filter(this.masterShares, o => o.expired);
          const indexes = [];
          _.forEach(a, (value) => {
            indexes.push(value.id);
          });
          this.loading = true;
          this.$http.post(`${this.$api_url}/delete_master_share`, { indexes }).then(() => {
            this.loading = false;
            _.forEach(a, (value) => {
              this.$delete(this.masterShares, this.masterShares.findIndex(b => b === value));
            });
            this.loading = false;
          });
        }
      });
    },
  },
  computed: {
    ordered_shares() {
      return _.reverse(_.sortBy(this.masterShares, o => o.id));
    },
  },
  filters: {
    date(value) {
      if (value !== 'Jamais') {
        return moment(value).locale('fr').calendar();
      }
      return 'Jamais';
    },
  },
  data() {
    return {
      duration: 0,
      searchTerm: '',
      ticksLabels: [
        '7 jours',
        '1 mois',
      ],
      loading: false,
      dialog: false,
      currentShare: null,
      headers: [{
        text: 'Id',
        align: 'left',
        sortable: true,
        value: 'id',
        filterable: false,
      },
      {
        text: 'Image',
        align: 'left',
        sortable: false,
        value: 'master.id',
        filterable: false,
      },
      {
        text: 'Nom',
        align: 'left',
        sortable: true,
        value: 'name',
      },
      {
        text: 'Master',
        align: 'left',
        sortable: true,
        value: 'master.name',
      },
      {
        text: 'UUID',
        align: 'left',
        sortable: true,
        value: 'uuid',
      },
      {
        text: 'Mot de passe',
        align: 'left',
        sortable: false,
        value: 'passw',
        filterable: false,
      },
      {
        text: 'Expiration',
        align: 'left',
        sortable: true,
        value: 'expires',
      },
      {
        text: 'Périmé',
        align: 'left',
        sortable: true,
        value: 'expired',
      },
      {
        text: 'Actions',
        align: 'left',
        sortable: false,
        value: 'actions',
        filterable: false,
      },
      ],
    };
  },
};
</script>

<style>

</style>
