<template>
<v-card v-if="currentClip.length > 0" :loading="saving">
    <v-card-title><v-btn icon @click="closeDialog()">
      <v-icon>mdi-close</v-icon></v-btn> {{currentClip[0].name}}
<v-spacer></v-spacer>
      <v-btn :disabled="canPrevious"
       v-shortkey="['ctrl', 'alt', 'o']" @shortkey="previous()"
        @click="previous" icon><v-icon>mdi-arrow-left</v-icon></v-btn>

      <v-btn :disabled="canNext" icon
       v-shortkey="['ctrl', 'alt', 'p']" @shortkey="next()" @click="next">
        <v-icon>mdi-arrow-right</v-icon></v-btn>
    </v-card-title>
    <v-card-subtitle>
     <v-spacer></v-spacer>
     {{currentClip[0].videoCodec}} @ {{currentClip[0].videoFps}} || {{currentClip[0].videoSize}}
    </v-card-subtitle>
    <v-card-text>

<v-row>
  <v-col>
    <hls-player  :source="hls"></hls-player>


    </v-col>
    <v-col v-if="!readOnly">
        <v-tabs>
    <v-tab href="#edit"><v-icon>mdi-pencil</v-icon> Editer</v-tab>
    <!--<v-tab  href="#transcript"><v-icon>mdi-text-to-speech</v-icon>Transcript</v-tab> --->
       <v-tab  href="#select"><v-icon>mdi-cart</v-icon>Sélections</v-tab>


  <v-tab-item value="edit">
    <clip-editor ref="editor" :currentClip.sync="currentClip[0]"
     ></clip-editor>
     </v-tab-item>

      <!---<v-tab-item value="transcript">FDJs</v-tab-item>-->


  <v-tab-item value="select">
    <selection-component @loading="child_loading=true"
    @loaded="child_loading=false" :currentClip="currentClip[0]"
    :selections="selections" ></selection-component>
     </v-tab-item>
 </v-tabs>
      </v-col>
    <v-col v-else>
      <b>Pays :</b> {{currentClip[0].pays}}<br>
            <b>Ville :</b> {{currentClip[0].ville}}<br>
            <b>Commentaires :</b> {{currentClip[0].comments}}<br>
            <b>Tags :</b> {{currentClip[0].tags}}<br>

    </v-col>

      </v-row></v-card-text>

  </v-card>
</template>

<script>
import HlsPlayer from '../player/HlsPlayer.vue';
import ClipEditor from './ClipEditor.vue';
import SelectionComponent from './SelectionComponent.vue';

export default {
  name: 'ClipEditDialog',
  components: {
    HlsPlayer, ClipEditor, SelectionComponent,
  },
  data() {
    return {
      saving: false,
    };
  },
  mounted() {
    console.log(this);
  },
  methods: {

    async previous() {
      this.saving = true;
      if (!this.readOnly) {
        await this.$refs.editor.changed();
      }
      this.saving = false;
      this.$emit('previous');
    },
    async next() {
      this.saving = true;
      if (!this.readOnly) {
        await this.$refs.editor.changed();
      }
      this.saving = false;
      this.$emit('next');
    },
    async closeDialog() {
      this.saving = true;
      if (!this.readOnly) {
        await this.$refs.editor.changed();
      }
      this.saving = false;
      this.$emit('cancel');
    },
  },

  props: {
    readOnly: {
      type: Boolean,
      default: false,
    },
    canNext: {
      type: Boolean,
      default: false,
    },
    selections: {
      type: Array,
      default: () => [],
    },
    canPrevious: {
      type: Boolean,
      default: false,
    },
    currentClip: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    hls() {
      return `${this.$base_url}/hls/asset/${this.currentClip[0].card}/,${this.currentClip[0].name}.mp4.urlset/master.m3u8`;
    },
  },
};
</script>

<style>

</style>
