import Vue from 'vue';
import Router from 'vue-router';
import Home from './views/Home.vue';
import Masters from './views/Masters.vue';
// eslint-disable-next-line import/no-cycle
import store from './store';
import LoginPage from './views/LoginPage.vue';
import MasterDeliver from './views/MasterDeliver.vue';
import ValidDeliver from './views/ValidDeliver.vue';
import ProjectDeliver from './views/ProjectDeliver.vue';
import CommentValid from './views/CommentValid.vue';
import Projects from './views/Projects.vue';
import ProjectDetail from './views/ProjectDetail.vue';
import ValidationTable from './views/ValidationTable.vue';
import PersoSpace from './views/PersoSpace.vue';
import UserProfile from './views/UserProfile.vue';
import NotFound from './views/NotFound.vue';
import SelectionComponent from './views/SelectionComponent.vue';
import AppsComponent from './views/AppsComponent.vue';
import ForgetPassword from './views/ForgetPassword.vue';
import ResetPassword from './views/ResetPassword.vue';
import Shares from './views/Shares.vue';
import Search from './views/Search.vue';
import SelShare from './views/SelShare.vue';


const ifAuthenticated = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    next();
    return;
  }
  if (store.getters.hasToken) {
    store.dispatch('getUserData').then(
      () => {
        next();
      },
    )
      .catch(() => {
        next(`/login?then=${encodeURIComponent(to.fullPath)}`);
      });
    return;
  }
  next(`/login?then=${encodeURIComponent(to.fullPath)}`);
};

const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    next();
    return;
  }
  next('/');
};
Vue.use(Router);
export default new Router({
  routes: [
    { path: '*', component: NotFound },
    {
      path: '/',
      name: 'home',
      component: Home,
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/forgetPassw',
      name: 'forget',
      component: ForgetPassword,
    },
    {
      path: '/createPassword/:reset_key',
      name: 'reset',
      component: ResetPassword,
    },
    {
      path: '/login',
      name: 'login',
      component: LoginPage,
      beforeEnter: ifNotAuthenticated,
    },

    {
      path: '/shareSel/:share_id',
      meta: { title: 'Home Page - Example App' },
      name: 'selShare',
      component: SelShare,
    },
    {
      path: '/masterShare/:share_id',
      meta: { title: 'Home Page - Example App' },
      name: 'masterShare',
      component: MasterDeliver,
    }, {
      path: '/validShare/:share_id',
      meta: { title: 'Home Page - Example App' },
      name: 'validShare',
      component: ValidDeliver,
    },
    {
      path: '/projectShare/:share_id',
      meta: { title: 'Home Page - Example App' },
      name: 'projectShare',
      component: ProjectDeliver,
    },
    {
      path: '/shares',
      name: 'shares',
      beforeEnter: ifAuthenticated,
      component: Shares,
      children: [
        {
          name: 'sahres_masters',
          path: '/shares/masters',
        },
        {
          name: 'sahres_valid',
          path: '/shares/validations',
        },
        {
          name: 'sahres_proj',
          path: '/shares/projets',
        },

      ],
    },
    {
      path: '/validations',
      name: 'valids',
      beforeEnter: ifAuthenticated,
      component: ValidationTable,
      children: [
        {
          name: 'valid_select',
          path: '/validations/:valid_id',
        },
      ],
    },
    {
      path: '/recherche',
      name: 'search',
      beforeEnter: ifAuthenticated,
      component: Search,
    },
    {
      path: '/profile',
      name: 'profile',
      beforeEnter: ifAuthenticated,
      component: UserProfile,
    },
    {
      path: '/persoSpace',
      name: 'persoSpace',
      beforeEnter: ifAuthenticated,
      component: PersoSpace,
    },
    {
      path: '/selections',
      name: 'selections',
      beforeEnter: ifAuthenticated,
      component: SelectionComponent,
      children: [
        {
          name: 'selction_play',
          path: '/selections/play',
        },
      ],
    },
    {
      path: '/validation/:val_id/edit',
      name: 'editvalid',
      component: CommentValid,
      beforeEnter: ifAuthenticated,
    },

    {
      path: '/projects',
      component: Projects,
      name: 'projects',
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/project_details/:project_id',
      component: ProjectDetail,
      name: 'project_detail',
      beforeEnter: ifAuthenticated,
      children: [
        {
          name: 'project_detail_card',
          path: '/project_details/:project_id/:card_id',
          children: [
            {
              name: 'project_detail_card_clip',
              path: '/project_details/:project_id/:card_id/:clip_id',
            },
          ],
        },
      ],
    },
    {
      path: '/apps',
      component: AppsComponent,
      name: 'apps',
      beforeEnter: ifAuthenticated,
    },

    {
      path: '/masters',
      component: Masters,
      name: 'masters',
      beforeEnter: ifAuthenticated,
      children: [
        {
          name: 'master_select',
          path: '/masters/:master_id',
        },
      ],
    },
  ],
});
