<template>
<div>
 Page non trouvée !!
 <v-btn to="/">Revenir au menu</v-btn>
</div>
</template>

<script>
export default {
  name: 'NotFound',
};
</script>

<style>

</style>
