<template>
<v-container class="fill-height" fluid>

    <v-row align="center" justify="center">

        <v-col cols="12" sm="8" md="4">

                <v-card class="elevation-12" :loading="loading">
                  <v-card-title>
                    <v-img  :src="require('../assets/my_logo.png')"
                    height="90px" width="90px"></v-img>
                  </v-card-title>
                    <v-toolbar dark flat>
                        <v-toolbar-title>Changer votre mot de passe</v-toolbar-title>
                        <div class="flex-grow-1"></div>

                    </v-toolbar>
                    <v-card-text v-if="!ok">
                        <v-form ref="form">
                            <v-text-field @keydown.enter.prevent="validate" :rules="emailRules"
                            type="email" v-model="mail" label="Email"></v-text-field>
                            <v-text-field @keydown.enter.prevent="validate" :rules="notNull"
                             v-model="user" label="Nom d'utilisateur"></v-text-field>
                        </v-form>
                        <v-card-actions>

                           <v-spacer></v-spacer>
                            <v-btn @click="validate" color="red">Réinitialiser</v-btn>
                        </v-card-actions>
                    </v-card-text>
                      <v-card-text v-else>
                        Votre mot de passe a été réinitialisé. Un email vous a été envoyé.
                      </v-card-text>
                </v-card>

        </v-col>
    </v-row>
</v-container>
</template>

<script>


export default {
  name: 'ForgetPassword',
  data: () => ({
    loading: false,
    mail: '',
    user: '',
    ok: false,
    emailRules: [
      v => !!v || 'E-mail is required',
      v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
    ],
    notNull: [
      v => !!v || 'Requis',

    ],

  }),
  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        this.$http.post(`${this.$api_url}/forget_passw`, {
          mail: this.mail,
          user: this.user,
        }).then((res) => {
          this.loading = false;
          if (res.data.ok === true) {
            this.ok = true;
          } else {
            this.ok = false;
            this.$dialog.notify.error('Impossible de réinitialiser le mot de passe.', {
              position: 'top-right',
              timeout: 5000,
            });
          }
        });
      }
    },

  },
};
</script>
