var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-dialog',{attrs:{"max-width":"80%"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[(_vm.dialog)?_c('master-details',{ref:"mastDetail",attrs:{"currentMaster":_vm.selected[0]},on:{"reload":_vm.loadMaseters,"cancel":function($event){_vm.selected=[]},"update:currentMaster":function($event){return _vm.$set(_vm.selected, 0, $event)},"update:current-master":function($event){return _vm.$set(_vm.selected, 0, $event)}}}):_vm._e()],1),_c('v-card-title',[_vm._v("Masters")]),_c('v-card-text',[_c('collection-switch',{model:{value:(_vm.collectionGroup),callback:function ($$v) {_vm.collectionGroup=$$v},expression:"collectionGroup"}}),_c('v-row',[(_vm.updating)?_c('v-col',[_c('v-progress-circular',{attrs:{"color":"primary","indeterminate":""}}),_vm._v(" Actualisation... ")],1):_vm._e(),_c('v-spacer'),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Chercher...","single-line":"","clearable":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-data-table',{directives:[{name:"touch",rawName:"v-touch",value:({
      left: function () { return _vm.swipe('Left'); },
      right: function () { return _vm.swipe('Right'); },

    }),expression:"{\n      left: () => swipe('Left'),\n      right: () => swipe('Right'),\n\n    }"}],attrs:{"single-select":"","loading":_vm.loading,"headers":_vm.headers,"search":_vm.search,"no-results-text":"Aucun master trouvé","items":_vm.masters,"item-key":"id","items-per-page":_vm.itemPerPage,"page":_vm.page},on:{"update:itemsPerPage":function($event){_vm.itemPerPage=$event},"update:items-per-page":function($event){_vm.itemPerPage=$event},"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
    var item = ref.item;
return [_c('b',[_c('text-highlight',{attrs:{"queries":_vm.light}},[_vm._v(_vm._s(item.name))])],1)]}},{key:"item.collection",fn:function(ref){
    var item = ref.item;
return undefined}},{key:"item.group",fn:function(ref){
    var item = ref.item;
return undefined}},{key:"item.comment",fn:function(ref){
    var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.light}},[_vm._v(_vm._s(item.comment))])]}},{key:"item.watermark",fn:function(ref){
    var item = ref.item;
return [(item.watermark)?_c('v-chip',{staticClass:"red white--text",attrs:{"left":""}},[_vm._v("W")]):_c('v-chip',{staticClass:"green white--text",attrs:{"left":""}},[_vm._v("M")])]}},{key:"item.id",fn:function(ref){
    var item = ref.item;
return [_c('v-avatar',[_c('v-img',{attrs:{"eager":true,"aspect-ratio":16/9,"src":_vm.ImgUrl + item.id +'/' + _vm.$store.state.token +'.jpg'+'?'+item.key,"width":"100px"}})],1)]}},{key:"item.action",fn:function(ref){
    var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){_vm.selected = [item]}}},on),[_c('v-icon',[_vm._v(" mdi-eye ")])],1)]}}],null,true)},[_c('span',[_vm._v("Voir les détails")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.shareMaster(item)}}},on),[_c('v-icon',[_vm._v(" mdi-share ")])],1)]}}],null,true)},[_c('span',[_vm._v("Partager")])])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }