<template>
<v-container class="fill">


  <v-col>
      <v-row>
        <v-col cols="12">
         <v-alert type="warning"  v-if="!this.$store.state.userData.settings.configured">
    Votre profil n'est pas renseigné. Pour utiliser les fonctions
     anvancées du MAM, merci de le compléter.
    <v-btn text to="/profile">Compléter mon profil</v-btn>
    </v-alert>
        </v-col>
      </v-row>
      <v-row>
         <v-col cols="12">
 <v-card
 :loading="loading"
    class="mx-auto"

    tile
    v-if="this.$store.state.userData.validation"
  >
    <v-list dense two-line>
      <v-subheader>Activité</v-subheader>
 <v-data-iterator
      :items="last_comments"
      :items-per-page="4"
      no-data-text=""
    >
    <template v-slot:default="props">
        <v-list-item  v-for="item in props.items" :key="item.msg">
          <v-list-item-avatar>
 <v-avatar v-if="item.pic"><v-img :src="item.pic"></v-img></v-avatar>
<v-avatar v-else color="red">{{item.display_name.slice(0, 1).toUpperCase()}}</v-avatar>

          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>

               {{item.display_name}} à commenté {{item.valid_name}},
               {{item.timestamp | moment_format}}
               </v-list-item-title>
                        <v-list-item-subtitle v-text="item.msg"></v-list-item-subtitle>

          </v-list-item-content>
           <v-list-item-action>
          <v-btn icon :to="'/validation/'+item.valid_id+'/edit'" >
            <v-icon color="grey lighten-1">mdi-information</v-icon>
          </v-btn>
        </v-list-item-action>
        </v-list-item>
    </template>
 </v-data-iterator>
    </v-list>

  </v-card >
         </v-col>
      </v-row>
      <v-row  v-if="$vuetify.breakpoint.mdAndDown">
        <v-col>
            <v-card :loading="loading"    v-if="this.$store.state.userData.validation">
                <v-card-title>Dernières validations</v-card-title>


<v-list three-line>
   <template v-for="(m) in valids ">
     <v-list-item
          :key="m.id"
          :to="'/validations/'+m.id"
        >
      <v-list-item-avatar>
            <v-img to="/validations" :eager="true"
                            :src="`${$api_url}/valid_th/${m.uuid}.jpg?${m.key}`">
        </v-img>

          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title v-html="m.name"></v-list-item-title>
            <v-list-item-subtitle v-html="m.date"></v-list-item-subtitle>
          </v-list-item-content>
     </v-list-item>
   </template>
</v-list>


            </v-card></v-col>
      </v-row>

        <v-row  v-if="$vuetify.breakpoint.mdAndDown">
        <v-col>
            <v-card :loading="loading"    v-if="this.$store.state.userData.masters">
                <v-card-title>Derniers Masters</v-card-title>
                <v-card-text>
<v-list three-line>
   <template v-for="(m) in masters ">
     <v-list-item
          :key="m.id"
          :to="'/masters/'+m.id"
        >
      <v-list-item-avatar>
            <v-img
                :src="`${$api_url}/master_thumb/${m.id}/${$store.state.token}.jpg?${m.key}`"
></v-img>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title v-html="m.name"></v-list-item-title>
            <v-list-item-subtitle v-html="m.date"></v-list-item-subtitle>
          </v-list-item-content>
     </v-list-item>
   </template>
</v-list>

                </v-card-text>
            </v-card></v-col>
      </v-row>
      <v-row v-if="!$vuetify.breakpoint.mdAndDown">
        <v-col>
            <v-card :loading="loading"    v-if="this.$store.state.userData.validation">
                <v-card-title>Dernières validations</v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col v-for="v in valids" :key="v.id" cols="12" md="4"  height="100%">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                   <router-link :to="'/validations/'+v.id">

                            <v-img to="/validations" v-on="on" :eager="true"
                            :src="`${$api_url}/valid_th/${v.uuid}.jpg?${v.key}`"
                             aspect-ratio="1"></v-img>

                        </router-link>
                                </template>
                                <span><b>{{v.name}}</b></span> - {{v.user}}
                            </v-tooltip>

                        </v-col>
                    </v-row>

                </v-card-text>
            </v-card>
        </v-col>
        <v-col>

            <v-card :loading="loading"  height="100%"
              v-if="this.$store.state.userData.masters">
                <v-card-title>Derniers masters</v-card-title>
                <v-card-text>

                    <v-row>
                        <v-col v-for="m in masters" :key="m.id" cols="12" md="4">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <router-link :to="'/masters/'+m.id">

                                        <v-img v-on="on"
                      :src="`${$api_url}/master_thumb/${m.id}/${$store.state.token}.jpg?${m.key}`"
                                        aspect-ratio="1"></v-img>
                                    </router-link>
                                </template>
                                <span><b>{{m.name}}</b></span> - {{m.user}}
                            </v-tooltip>

                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>

        </v-col>
    </v-row>
     <v-row>
    <v-col cols="12" md="6">

    <v-card  height="100%">
      <v-card-title>Le saviez vous ?</v-card-title>
      <v-card-text>
        <h4>{{advice.title}}</h4>
        {{advice.desc}}
      </v-card-text>
    </v-card>
      </v-col>
       <v-col cols="12" md="6">

    <v-card height="100%">
      <v-card-title>Nouveautés</v-card-title>

          <v-list-item two-line  v-for="c in changelog" :key="c.title">
      <v-list-item-content>
        <v-list-item-title>{{c.title}}</v-list-item-title>
        <v-list-item-subtitle>  {{c.desc}}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>


    </v-card>
      </v-col>
  </v-row>
  </v-col>

</v-container>
</template>

<script>
import moment from 'moment';

export default {

  data() {
    return {
      masters_data: [],
      last_comments: [],
      valids_data: [],
      advice: {},
      loading: true,
      changelog: {},
    };
  },
  filters: {
    moment_format(value) {
      if (!value) return '';
      return moment(value).locale('fr').calendar();
    },
  },
  token() {
    return localStorage.getItem('token');
  },
  computed: {
    valids() {
      return this.valids_data;
    },
    masters() {
      return this.masters_data;
    },
  },
  mounted() {
    this.$http.get(`${this.$api_url}/hello`).then(
      (res) => {
        this.masters_data = res.data.last_masters;
        this.valids_data = res.data.last_valid;
        this.advice = res.data.tip;
        this.changelog = res.data.changelog;
        this.last_comments = res.data.last_comments;
        this.loading = false;
      },
    );
  },
};
</script>
