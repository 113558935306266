<template>
<div  class="text-center">
     <v-tooltip bottom="">
        <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" @click="dialog = true">
                <v-icon>mdi-information</v-icon>
            </v-btn>
        </template><span>Informations sur la carte</span>
      </v-tooltip>
  <v-dialog v-model="dialog" width="80%">
        <v-card>
            <v-card-title primary-title>
                <v-btn icon @click="dialog=false">
                    <v-icon>mdi-close</v-icon></v-btn>{{currentCard.name}}
            </v-card-title>

            <v-card-text>
                <v-row>
                <v-col>
                <v-list dense="">
                    <v-list-item two-line>
                        <v-list-item-content>
                            <v-list-item-title>Disque</v-list-item-title>
                            <v-list-item-subtitle>{{currentCard.disk}}
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item two-line>
                        <v-list-item-content>
                            <v-list-item-title>
                                N° Interne
                            </v-list-item-title>
                            <v-list-item-subtitle>
                                {{currentCard.id}}
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item two-line>
                        <v-list-item-content>
                            <v-list-item-title>En ligne</v-list-item-title>
                            <v-list-item-subtitle>
                                <v-chip color="green"
                                v-if="currentCard.on_raid">OUI</v-chip>
                                <v-chip color="red" v-else>NON</v-chip>
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item two-line>
                        <v-list-item-content>
                            <v-list-item-title>Ingesté par

                            </v-list-item-title>
                            <v-list-item-subtitle>
                                {{currentCard.ingested_by}}
                                </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>

                </v-list>
                </v-col>
                <v-col>
                <v-list>
                    <v-list-item two-line>
                        <v-list-item-content>
                            <v-list-item-title>
                                Date d'ingest</v-list-item-title>
                            <v-list-item-subtitle>
                                {{currentCard.ingest_date}}
                                </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item two-line>
                        <v-list-item-content>
                            <v-list-item-title>
                                Date de tournage</v-list-item-title>
                            <v-list-item-subtitle>
                                {{currentCard.tourn_date}}
                                </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item two-line>
                        <v-list-item-content>
                            <v-list-item-title>JRI</v-list-item-title>
                            <v-list-item-subtitle>
                                {{currentCard.jri}}
                                </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item two-line>
                        <v-list-item-content>
                            <v-list-item-title
                            >Propriétaire des images
                            </v-list-item-title>
                            <v-list-item-subtitle>
                                {{currentCard.owner}}
                                </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
                </v-col>
                </v-row>
            </v-card-text>


        </v-card>
    </v-dialog>
</div>

</template>

<script>
export default {
  name: 'CardInfo',
  data() {
    return {
      dialog: false,
    };
  },
  props: {
    currentCard: {
      type: Object,

    },
  },

};
</script>

<style>

</style>
