import Vue from 'vue';
import Vuex from 'vuex';
// eslint-disable-next-line import/no-extraneous-dependencies
import axios from 'axios';
import EventBus from '@/utils';
import { API_URL } from './api';


Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token: localStorage.getItem('token') || '',
    userData: {},
  },
  getters: {
    hasToken(state) {
      if (state.token === '') {
        return false;
      }
      return true;
    },
    isAuthenticated(state) {
      if (state.token === '' || state.userData.settings === undefined) {
        return false;
      }
      return true;
    },

  },
  mutations: {
    logout(state) {
      localStorage.removeItem('token');
      state.jwt = '';
      window.location.reload();
    },


    setUserData(state, payload) {
      Vue.set(state, 'userData', payload);
    },

    setJwtToken(state, payload) {
      state.token = payload.jwt;
    },
  },
  actions: {
    setInterceptor(context) {
      axios.interceptors.response.use(response => response, (error) => {
        try {
          if (error.response.status === 401) {
            if (error.response.data.code === 418) {
              return context.dispatch('RefreshToken', error.response).then(
                (token) => {
                  // eslint-disable-next-line no-param-reassign
                  error.config.headers.Authorization = `Bearer ${token}`;
                  return Vue.axios(error.config);
                },
              );
            }
          }
          EventBus.$emit('networkError', { data: error });

          return Promise.reject(error);
        } catch (e) {
          EventBus.$emit('networkError', { data: error, exception: e });
          return new Promise(() => {});
        }
      });
    },
    RefreshToken(context) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'GET',
          url: `${API_URL}/renew_token`,
          headers: { Authorization: `Bearer ${context.state.token}` },
        }).then((resp) => {
          // eslint-disable-next-line no-param-reassign
          context.state.token = resp.data.token;
          localStorage.setItem('token', resp.data.token);
          Vue.axios.defaults.headers.common.Authorization = `Bearer ${resp.data.token}`;
          resolve(resp.data.token);
        }).catch(e => reject(e));
      });
    },
    getUserData(context) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'GET',
          url: `${API_URL}/userCred`,
          headers: { Authorization: `Bearer ${context.state.token}` },
        }).then((response) => {
          context.dispatch('setInterceptor');
          context.commit('setUserData', response.data);
          resolve();
        }).catch(e => reject(e));
      });
    },

    login(context, userData) {
      return new Promise((resolve, reject) => {
        Vue.axios.post(`${API_URL}/login`, userData)
          .then((response) => {
            localStorage.setItem('token', response.data.token);
            context.commit('setJwtToken', { jwt: response.data.token });
            Vue.axios.defaults.headers.common.Authorization = `Bearer ${response.data.token}`;
            context.dispatch('setInterceptor');
            context.commit('setUserData', response.data.data);
            resolve();
          }).catch(e => reject(e));
      });
    },


  },
});
