<template>
<div>
    <v-dialog v-model="dialog" max-width="80%">
        <v-card :loading="loading">
            <v-card-title>Séléctionner les cartes</v-card-title>
            <v-card-text>
  <v-card
          class="mb-12"
          color="black lighten-1"
          height="300px"
          style="overflow: auto;"
        >

        <v-card-text>

          <v-btn text color="success" @click="allCards">Toutes les cartes</v-btn>
             <v-checkbox dense="" v-for="c in cards"
                :key="c.id" v-model="selectedCards"
                :label="c.name" :value="c.id"
                ></v-checkbox>
        </v-card-text></v-card>


            </v-card-text>
            <v-card-actions v-if='action'>
                <v-spacer></v-spacer>
                <v-btn text @click="reinit">Annuler</v-btn>
                <v-btn color="success" @click="download">
                  <v-icon>{{action.icon}}</v-icon>{{action.title}}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <v-menu offset-y>
        <template v-slot:activator="{ on }">
            <v-btn icon color="primary" v-on="on">
                <v-icon>mdi-download</v-icon>
            </v-btn>
        </template>
        <v-list dense >
            <v-list-item v-for="a in actions" :key="a.icon" @click="dl(a)" :disabled="a.disabled">
                <v-list-item-icon>
                    <v-icon>{{a.icon}}</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{a.title}}
                </v-list-item-title>
            </v-list-item>

        </v-list>
    </v-menu>
</div>
</template>

<script>

export default {
  name: 'DownloadComponenet',
  props: {
    projectName: {
      type: String,
      default: '',
    },
    cards: {
      type: Array,
      default: () => [],
    },
    currentCard: {
      type: Number,
      default: null,
    },
  },
  watch: {
    currentCard(newValue) {
      this.$set(this.selectedCards, 0, newValue);
    },
  },
  data() {
    return {
      selectedCards: [this.currentCard],
      loading: false,
      action: null,
      actions: {
        ale: {
          title: "Télécharger l'ale pour avid / Première",
          icon: 'mdi-view-list',
          code: 'ale',
          disabled: false,
        },
        xls: {
          title: 'Téléchager au format xls',
          icon: 'mdi-google-spreadsheet',
          code: 'xls',
          disabled: false,
        },
        mp4: {
          title: 'Télécharger les proxys',
          icon: 'mdi-file-video',
          code: 'mp4',
          disabled: false,
        },
        tts: {
          title: 'Télécharger les transcripts',
          icon: 'mdi-text-to-speech',
          code: 'tts',
          disabled: true,
        },
        arch: {
          title: "Télécharger l'archive mamArch",
          icon: 'mdi-archive',
          code: 'arch',
          disabled: false,
        },
      },
      dialog: false,
    };
  },
  methods: {
    download() {
      this.loading = true;
      const cards = [...new Set(this.selectedCards)];
      this.$http({
        url: `${this.$api_url}/generate`,
        data: {
          cards,
          req: this.action.code,
        },
        method: 'POST',
        responseType: 'blob',
      })
        .then((res) => {
          this.loading = false;
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${this.projectName}_${this.action.code}.zip`);
          document.body.appendChild(link);
          link.click();
          this.reinit();
        })
        .catch(() => {
          this.loading = false;
          this.$dialog.notify.error('une erreur est survenue', {
            position: 'top-centered',
            timeout: 10000,
          });
        });
    },
    allCards() {
      this.cards.forEach((c) => {
        if (!this.selectedCards.includes(c)) { this.selectedCards.push(c.id); }
      });
    },
    reinit() {
      this.dialog = false;
      this.action = null;
      this.selectedCards = [this.currentCard];
    },
    dl(e) {
      this.action = e;
      this.dialog = true;
    },
  },
};
</script>

<style>

</style>
