<template>
<v-flex xs12>

    <v-skeleton-loader class="mx-auto" max-width="400" type="card" :loading="loading">
        <v-card class="mx-auto" max-width="500" :loading="card_loading" v-if="!granted">
            <v-img class="white--text align-end" height="200px" :src="this.master_data.image">
                <v-card-title> Accès à la validation
                </v-card-title>

            </v-img>

            <v-card-text v-if="master_data.ready" class="text--primary">
                {{master_data.name}}
                <v-text-field type="password" @keypress="error_message=''"
                 @keypress.native.enter="requestAccess" :error-messages="error_message"
                 label="Mot de passe" v-model="password"></v-text-field>

            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn v-if="!granted && master_data.ready"
                @click="requestAccess" color="accent" text>
                    Valider
                </v-btn>

            </v-card-actions>
        </v-card>

  <v-img  width="15%" v-if="granted"  :src="require('@/assets/galaxie.png')"> </v-img>

        <valid-commenter v-if="granted" :valid_data="valid_data"
  :show_timeline="master_data.comment_allowed"
  :loading="loading"
  :user="user"
  :pic="pic"
  :display_name="user">
  </valid-commenter>

<v-btn class="success" v-if="granted" :loading=download
:href=valid_data.dl
                         target="_blank"
>
  <v-icon>mdi-download</v-icon> Télécharger</v-btn>
    </v-skeleton-loader>

</v-flex>
</template>

<script>
import ValidCommenter from '../components/ValidCommenter.vue';

export default {
  name: 'ValidDeliver',
  components: {
    ValidCommenter,
  },
  methods: {
    download_valid() {
      this.download = true;
    },
    seeMaster() {
      this.seeing = true;
      this.action = true;
    },
    requestAccess() {
      this.card_loading = true;
      this.$http.post(`${this.$api_url}/validate_valid_share`, {
        uuid: this.master_data.uuid,
        password: this.password,
      }).then((res) => {
        if (res.data.ok === false) {
          this.error_message = 'Mauvais mot de passe';
          this.granted = false;
        } else {
          this.locked_data.hls = res.data.ok.hls;
          this.locked_data.comments = res.data.ok.comments;
          this.valid_data = res.data.ok.valid_data;
          this.valid_data.dl = res.data.ok.dl;
          this.user = res.data.ok.user_name;
          this.granted = true;
        }
      });

      this.card_loading = false;
    },
  },
  data() {
    return {
      loading: true,
      download: false,
      granted: false,
      card_loading: false,
      error_message: '',
      user: '',
      pic: null,
      password: '',
      master_data: {},
      valid_data: {},
      locked_data: {
        dl: false,
        hls: undefined,
      },

    };
  },
  mounted() {
    this.$http.get(`${this.$api_url}/share_valid/${this.$route.params.share_id}`).then((result) => {
      this.master_data = result.data;
      this.loading = false;
    });
  },
};
</script>
