<template>
<v-card>
  <v-dialog width="80%"
      v-model="dialog"
  >
      <valid-details ref="validDetail"
      @reload="loadValid" :valid.sync="selected[0]"
      v-if="selected[0] !== undefined" @cancel="selected=[]"></valid-details></v-dialog>
  <v-card-title>Validations</v-card-title>
  <v-card-text v-if="this.$store.state.userData.validation">

        <collection-switch v-model="collectionGroup"></collection-switch>
        <v-row>
          <v-spacer></v-spacer>
          <v-col v-if="updating">
           <v-progress-circular color="primary" indeterminate ></v-progress-circular>
          Actualisation...
          </v-col> <v-spacer></v-spacer>

            <v-col cols="6">
                <v-text-field v-model="search"
                append-icon="mdi-magnify" label="Chercher..."
                clearable="" single-line  hide-details></v-text-field>
            </v-col>
        </v-row>
         <v-data-table
    :headers="headers"
    :items="valids"
    :items-per-page.sync="itemPerPage"
    :page.sync="page"
    :search="search"
    :loading="loading">
  <template v-slot:item.name="{ item }">
                 <b><text-highlight :queries="light">{{item.name}}</text-highlight></b>
             </template>
              <template v-slot:item.comment="{ item }">
                 <text-highlight :queries="light">{{item.comment}}</text-highlight>
             </template>
<template v-slot:item.group="{ item }"></template>
<template v-slot:item.id="{ item }">
  <v-avatar>
<v-img :aspect-ratio="16/9" :eager="true"
     :src="$api_url +'/valid_th/' + item.uuid +'.jpg'+'?'+item.key" width="100px" />
  </v-avatar>
</template>
<template v-slot:item.collection="{ item }"></template>
<template v-slot:item.action="{ item }">
  <v-row>
   <v-tooltip bottom>
      <template v-slot:activator="{ on }">
                <v-btn v-on="on" icon @click="selected = [item]">

                    <v-icon>
                        mdi-eye
                    </v-icon>
                </v-btn>
      </template>
        <span>Voir les détails</span>
      </v-tooltip>
       <v-tooltip bottom>
      <template v-slot:activator="{ on }">
<v-badge>

      <template v-slot:badge>{{item.comments}}</template>
     <v-btn  v-on="on" icon :to="'/validation/'+item.id+'/edit'" >

      <v-icon>
        mdi-message
      </v-icon></v-btn>
    </v-badge></template>
        <span>Commenter</span>
      </v-tooltip>
        <v-tooltip bottom>
      <template v-slot:activator="{ on }">
                <v-btn v-on="on" icon @click="shareValid(item)">

                    <v-icon>
                        mdi-share
                    </v-icon>
                </v-btn>
      </template>

        <span>Partager</span>
      </v-tooltip>
  </v-row>
      </template>
  </v-data-table>
  </v-card-text>
  <v-card-text v-if="!this.$store.state.userData.validation">
    Votre compte ne vous permet pas de gérer les validations </v-card-text>
</v-card>
</template>

<script>
import _ from 'lodash';
import CollectionSwitch from '../components/CollectionSwitch.vue';
import ValidDetails from '../components/validations/ValidDetails.vue';

export default {
  name: 'ValidationTable',
  components: {
    CollectionSwitch, ValidDetails,
  },
  computed: {
    light() {
      if (this.search) {
        return [this.search];
      }
      return '';
    },
  },
  data() {
    return {
      updating: false,
      loading: true,
      valids: [],
      dialog: false,
      page: 1,
      itemPerPage: 5,
      headers: [

        {
          text: '',
          value: 'id',
        },
        {
          text: 'Nom',
          value: 'name',


        },
        {
          text: 'Rangé dans...',
          value: 'path',

        },
        {
          text: 'Date',
          value: 'date',
          filterable: false,
        },

        {
          text: '',
          value: 'collection',
          sortable: false,
          filter: this.collectionFilter,

        },

        {
          text: 'Utilisateur',
          value: 'user',
          sortable: false,
          filterable: false,

        },

        {
          text: '',
          value: 'group',
          filter: this.groupFilter,
          sortable: false,

        },

        {
          text: 'Actions', value: 'action', sortable: false, filterable: false,
        },

      ],
      search: '',
      selected: [],
      collectionGroup: { currentGroup: undefined, currentCol: undefined },
    };
  },
  methods: {
    shareValid(item) {
      this.selected = [item];
      this.$nextTick(() => {
        if (this.$store.state.userData.admin) {
          this.$refs.validDetail.currentTab = 3;
        } else {
          this.$refs.validDetail.currentTab = 2;
        }
      });
    },
    loadValid() {
      let tempProjects = localStorage.getItem('valids');
      if (tempProjects != null) {
        this.valids = JSON.parse(tempProjects);
        this.loading = false;
      } else {
        localStorage.setItem('valids', JSON.stringify([]));
        tempProjects = localStorage.getItem('valids');
      }
      this.updating = true;

      this.$http.get(`${this.$api_url}/validations`).then((res) => {
        this.loading = false;
        localStorage.setItem('valids', JSON.stringify(res.data));
        if (!_.isEqual(JSON.parse(tempProjects).sort(), res.data.sort())) {
          this.valids = res.data;
        }
        if (this.$route.params.valid_id) {
          this.selected = [_.find(this.valids,
            o => o.id === parseInt(this.$route.params.valid_id, 10))];
        }
        this.updating = false;
      });
    },
    groupFilter(value) {
      if (this.collectionGroup.currentGroup === undefined) {
        return true;
      }
      return value === this.collectionGroup.currentGroup;
    },
    collectionFilter(value) {
      if (this.collectionGroup.currentCol === undefined) {
        return true;
      }
      return value === this.collectionGroup.currentCol;
    },
    nameFilter(value) {
      // If this filter has no value we just skip the entire filter.
      if (!this.search) {
        return true;
      }
      // Check if the current loop value (The dessert name)
      // partially contains the searched word.
      return value.toLowerCase().includes(this.search.toLowerCase());
    },

  },
  watch: {
    itemPerPage(value) {
      const quer = _.clone(this.$route.query);
      quer.of = value;
      this.$router.push({ path: this.$route.path, query: quer });
    },
    page(value) {
      const quer = _.clone(this.$route.query);
      quer.page = value;
      this.$router.push({ path: this.$route.path, query: quer });
    },
    search(value) {
      const quer = _.clone(this.$route.query);
      if (value === null) {
        quer.filter = undefined;
      } else { quer.filter = value; }

      this.$router.push({ path: this.$route.path, query: quer });
    },
    dialog(value) {
      if (value === false) {
        this.selected = [];
        const quer = _.clone(this.$route.query);
        quer.page = this.page;
        quer.of = this.itemPerPage;
        quer.filter = this.filter;
        this.$router.push({ path: '/validations', query: quer });
      }
    },
    selected(newValue) {
      this.dialog = true;
      if (newValue[0]) {
        this.$router.push({
          name: 'valid_select',
          params: {
            valid_id: newValue[0].id,
          },
        });
      } else {
        this.dialog = false;
      }
    },

  },
  mounted() {
    this.loadValid();
    this.search = this.$route.query.filter;
    const quer = _.clone(this.$route.query);
    if (quer.page && quer.of) {
      this.itemPerPage = parseInt(quer.of, 10);
      this.page = parseInt(quer.page, 10);
    } else {
      this.page = 1;
      this.itemPerPage = 5;
    }
  },
};
</script>

<style>

</style>
