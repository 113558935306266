<template>
<v-data-table :loading="loading" :items="project" :item-key="id" :headers="headers">

    <template v-slot:item.online="{ item }">

        <v-chip v-if="item.online" color="green">Oui</v-chip>
        <v-chip v-else color="red">Oui</v-chip>
    </template>
    <template v-slot:item.actions="{ item }">
        <v-btn icon @click="umount(item)"><v-icon>mdi-delete</v-icon></v-btn>
    </template>
</v-data-table>
</template>

<script>
export default {
  name: 'MountTable',
  methods: {
    umount(item) {
      this.$confirm('Voulez-vous vraiment supprimer la carte de votre projet?').then((res) => {
        this.loading = true;
        if (res === true) {
          this.$http.post(`${this.$api_url}/umount`, { id: item.id, space: this.space }).then(() => {
            this.$delete(this.project, this.project.indexOf(item));
            this.loading = false;
          });
        }
      });
    },
  },
  data() {
    return {
      loading: false,
      headers: [
        {
          text: 'Nom',
          sortable: false,
          value: 'name',
        },
        {
          text: 'En ligne',
          value: 'online',
        },
        {
          text: 'Actions',
          value: 'actions',
        },
      ],
    };
  },
  props: {
    project: {
      type: Array,
      default: () => [],
    },
    space: { type: String },
  },
};
</script>

<style>

</style>
