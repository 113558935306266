<template>
<v-row>
<v-card flat><v-card-title>Progression du master</v-card-title>
<v-card-text>
    <v-progress-linear v-model="progress" :indeterminate="indeter"></v-progress-linear>

    </v-card-text></v-card>
</v-row>
</template>

<script>
export default {
  name: 'MasterProgress',
  data() {
    return {
      progress: 0,
      indeter: true,
      shouldStop: false,
    };
  },
  mounted() {
    this.getMasterProgress();
  },
  beforeDestroy() {
    this.shouldStop = true;
  },
  methods: {
    getMasterProgress() {
      this.$http.get(`${this.$api_url}/master_progress/${this.master.id}`).then((res) => {
        if (res.data.ready === true) {
          this.master.ready = true;
          this.master.key = this.master.key + 1;
        }
        if (res.data.progress > 0) {
          this.indeter = false;
          this.progress = res.data.progress;
        }
        this.master.progress = res.data.progress;
        if (this.shouldStop !== true) {
          setTimeout(this.getMasterProgress, 3000);
        }
      });
    },
  },
  props: {
    master: {
      type: Object,
      default: null,
    },
  },
};
</script>

<style>

</style>
