<template>
<v-card :loading="loading" flat>
    <v-card-text>

        <v-form ref="form">
            <v-text-field label="Nom du master" :rules="nameRules"
            v-model="tempValid.name"></v-text-field>
            <v-text-field label="Commentaires" :rules="commentRules" required
            v-model="tempValid.comment"></v-text-field>
            <v-label>Ranger dans...</v-label>
            <collection-switch  v-model="collectionGroup"></collection-switch>
            <v-label color="red" v-if="error">Veuillez sélectionner un groupe</v-label>
        </v-form>

    </v-card-text>
    <v-card-actions>
      <v-btn @click="deleteValid" color="red">Supprimer</v-btn>
      <v-spacer></v-spacer>

<v-btn text @click="$emit('cancel')">Annuler</v-btn>
       <v-btn color="green" @click="saveValid">Sauver</v-btn>
      </v-card-actions>
</v-card>
</template>

<script>
import _ from 'lodash';
import CollectionSwitch from '../CollectionSwitch.vue';

export default {
  name: 'ValidEdit',
  components: {
    CollectionSwitch,
  },
  props: {
    valid: {
      type: Object,
      default: undefined,
    },
  },
  methods: {
    deleteValid() {
      this.$confirm('Voulez-vous vraiment supprimer le master ?').then((res) => {
        if (res === true) {
          this.loading = true;

          this.$http.post(`${this.$api_url}/deleteValid`, { id: this.valid.id }).then(() => {
            this.loading = false;
            this.$emit('reload');
            this.$emit('cancel');
          });
        }
      });
    },
    saveValid() {
      if (this.$refs.form.validate()) {
        if (this.collectionGroup.currentGroup === undefined) {
          this.error = true;

          return;
        }
        this.loading = true;
        this.error = false;
        this.tempValid.group = this.collectionGroup.currentGroup;
        this.tempValid.collection = this.collectionGroup.currentCol;
        this.$http.post(`${this.$api_url}/updateValid`, this.tempValid).then(() => {
          this.$set(this.valid, 'name', this.tempValid.name);
          this.$set(this.valid, 'comment', this.tempValid.comment);
          this.$set(this.valid, 'collection', this.collectionGroup.currentCol);
          this.$set(this.valid, 'group', this.collectionGroup.currentGroup);
          this.loading = false;
          this.$emit('cancel');
        });
      }
    },
  },
  data() {
    return {
      tempValid: _.clone(this.valid),
      loading: false,
      error: false,
      nameRules: [
        v => !!v || 'Les nom est requis',
      ],
      commentRules: [
        v => !!v || 'Une description est requise',
      ],
      collectionGroup: {
        currentCol: this.valid.collection,
        currentGroup: this.valid.group,
      },
    };
  },

};
</script>

<style>

</style>
