<template>
<v-flex>
    <v-snackbar v-model="snackbar">
        Sauvegarde...
        <div class="loader"></div>
    </v-snackbar>
    <v-row>
        <v-card-title>{{valid_data.name}}</v-card-title>
    </v-row>
    <v-row>
        <v-col cols="6">
            <v-card>

                <hls-player :playing.sync="playing" ref="player"
                @currentTimeChanged="changeCurrentTime" v-if="!loading"
                @durationChanged="loadTimeline" :source="valid_data.hls"></hls-player>

            </v-card>
        </v-col>
        <v-col cols="6">
            <v-card v-if="currentComment.time">
                <v-card-title>
                    {{currentComment.open_by_display}} || {{currentComment.time | timecode}}
                </v-card-title>
                <v-card-text :style="calculatedWidth" class="overflow-x-auto">

                    <v-list subheader v-for="comment in currentComment.comments" :key="comment.id">
                        <v-list-item two-line>
                            <v-list-item-avatar>
                                <v-img v-if="comment.pic" :src="comment.pic"></v-img>
                                <v-avatar v-else color="red">{{comment.display_user}}</v-avatar>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title>{{comment.display_name}}</v-list-item-title>
                                <v-list-item-subtitle>{{comment.timestamp | moment_format}} :
                                </v-list-item-subtitle>
                                <span v-if="!editing"> {{comment.msg}}</span>
                                <v-text-field v-else multi-line hint="Entrée pour valider"
                                @keyup.enter.native="stopEditComment" data-vv-delay="1000"
                                :value="comment.msg" @change="v =>comment.msg= v"></v-text-field>
                            </v-list-item-content>
                            <v-list-item-action v-if="comment.editable">
                                <v-btn icon @click="deleteComment(comment)">
                                    <v-icon color="red">mdi-delete</v-icon>
                                </v-btn>
                            </v-list-item-action>
                            <v-list-item-action v-if="comment.editable">
                                <v-btn icon @click="startEditComment">
                                    <v-icon>mdi-pencil</v-icon>
                                </v-btn>
                            </v-list-item-action>
                        </v-list-item>

                    </v-list>

                </v-card-text>

                <div ref="bottom"></div>
                <v-card-actions>
                    <v-text-field v-model="tmpResponse"
                    @keyup.enter.prevent="createResponse"
                    label="Répondre..." prepend-icon="mdi-message-reply"
                    multi-line hint="Entrée pour valider"></v-text-field>
                </v-card-actions>
            </v-card>
        </v-col>

    </v-row>
    <v-row>
        <player-timeline  v-if="show_timeline" ref="validTimeline" :valid_id="valid_data.id"
        :size.sync="size" @oneFrameBack="oneFrameBack"
        @oneFrameForward="oneFrameForward" :user="user"
        :pic="pic" :display_name="display_name"
        :userSelectedComment.sync="currentComment"
        :comments.sync="valid_data.comments" :playing="playing"
        @playPause="playPause" @pause="pause" @position="setPosition"
        @pv-if="!loading"></player-timeline>

    </v-row>

</v-flex>
</template>

<script>
import moment from 'moment';
import _ from 'lodash';
import HlsPlayer from './player/HlsPlayer.vue';
import PlayerTimeline from './player/VideoTimeline/PlayerTimeline.vue';

export default {
  name: 'ValidCommenter',
  components: {
    HlsPlayer,
    PlayerTimeline,
  },

  watch: {
    currentComment(newValue) {
      if (newValue.time) {
        this.$refs.player.player.seek(newValue.time);
        this.editing = false;
      }
    },
    'valid_data.comments': {
      handler() {
        this.snackbar = true;

        const a = _.cloneDeep(this.valid_data.comments);
        a.forEach((element) => {
          // eslint-disable-next-line no-param-reassign
          delete element.open_by_display;
          element.comments.forEach((element2) => {
            // eslint-disable-next-line no-param-reassign
            delete element2.pic;
            // eslint-disable-next-line no-param-reassign
            delete element2.display_name;
          });
        });
        this.$http.post(`${this.$api_url}/validation/comment/${this.valid_data.id}`, {
          data: a,
        }).then(() => {
          this.snackbar = false;
        });
      },
      deep: true,
    },
  },
  props: {
    show_timeline: {
      type: Boolean,
      default: true,
    },
    valid_data: {
      type: Object,
      default: () => {},
    },
    loading: {
      type: Boolean,
    },
    user: {
      type: String,
    },
    pic: {
      type: String,
    },
    display_name: {
      type: String,
    },
  },
  data() {
    return {
      tmpResponse: '',
      playing: '',
      snackbar: false,
      valid_name: '',
      loaded: false,

      currentComment: {},
      editing: false,
      size: 0,
    };
  },
  filters: {
    moment_format(value) {
      if (!value) return '';
      return moment(value).locale('fr').calendar();
    },
  },
  computed: {

    valid() {
      return this.valid_data;
    },
    calculatedWidth() {
      return `height:${this.$refs.player.$el.getBoundingClientRect().height * 0.55}px`;
    },

  },
  methods: {
    setPosition(time) {
      this.$refs.player.player.seek(time);
    },
    deleteComment(comment) {
      this.$confirm('Voulez-vous vraiment supprimer ?').then((res) => {
        if (res === true) {
          const newComments = _.clone(this.currentComment.comments);
          _.remove(newComments, n => n === comment);
          if (newComments.length === 0) {
            const tmpComments = _.clone(this.valid_data.comments);
            _.remove(tmpComments, n => n === this.currentComment);
            this.$set(this.valid_data, 'comments', tmpComments);
            this.currentComment = {};
          } else {
            this.$set(this.currentComment, 'comments', newComments);
          }
        }
      });
    },
    oneFrameForward() {
      this.$refs.player.pause();
      this.$refs.player.player.seek(this.$refs.validTimeline.currentTime + 0.04);
    },
    oneFrameBack() {
      this.$refs.player.pause();
      this.$refs.player.player.seek(this.$refs.validTimeline.currentTime - 0.04);
    },
    createResponse() {
      const response = {
        user: this.user,
        msg: this.tmpResponse,
        pic: this.pic,
        editable: true,
        display_name: this.display_name,
        timestamp: moment().toString(),
      };
      this.$set(this.currentComment.comments, this.currentComment.comments.length, response);
      this.tmpResponse = '';
    },
    stopEditComment() {
      this.editing = false;
      this.$refs.player.player.play();
      this.$vuetify.goTo(this.$refs.bottom);
    },
    startEditComment() {
      this.editing = true;
      this.$refs.player.player.pause();
    },

    pause() {
      this.$refs.player.pause();
    },
    playPause() {
      this.$refs.player.playPause();
    },
    changeCurrentTime(time) {
      this.$refs.validTimeline.setCurentTime(time);
    },
    loadTimeline() {
      this.$refs.validTimeline.setDuration(this.$refs.player.max);
    },
  },

};
</script>

<style lang="scss" scoped>
.loader {
    border: 5px solid #f3f3f3;
    border-radius: 50%;
    border-top: 5px solid #3498db;
    width: 20px;
    height: 20px;
    -webkit-animation: spin 2s linear infinite;
    /* Safari */
    animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>
