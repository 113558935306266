<template>
<v-row>
    <v-col>
        <v-skeleton-loader :loading="col_loading"
        height="94" type="list-item-two-line">
            <v-autocomplete v-model="value.currentCol"
             :items="collections" clearable dense chips
             v-on:change="changeCollection" label="Sélectionner une collection">
                <template v-slot:selection="data">
                    <v-chip :key="JSON.stringify(data.item)"
                     v-bind="data.attrs"
                     :input-value="data.selected" :disabled="data.disabled"
                      @click.stop="data.parent.selectedIndex = data.index"
                       @click:close="data.parent.selectItem(data.item)">
                        <v-avatar class="accent white--text"
                        left>{{ data.item.text.slice(0, 1).toUpperCase() }}</v-avatar>
                        {{ data.item.text}}
                    </v-chip>
                </template>
            </v-autocomplete>
        </v-skeleton-loader>
    </v-col>
    <v-col>
        <v-skeleton-loader :loading="group_loading"
        height="40" type="list-item">
            <v-autocomplete v-model="value.currentGroup"
             dense :items="groups" v-on:change="changeGroup"
             clearable chips label="Sélectionner un projet">
                <template v-slot:selection="data">
                    <v-chip :key="JSON.stringify(data.item)"
                     v-bind="data.attrs"
                     :input-value="data.selected"
                     :disabled="data.disabled"
                      @click.stop="data.parent.selectedIndex = data.index"
                       @click:close="data.parent.selectItem(data.item)">
                        <v-avatar class="accent white--text" left>
                          {{ data.item.text.slice(0, 1).toUpperCase() }}</v-avatar>
                        {{ data.item.text}}
                    </v-chip>
                </template>
            </v-autocomplete>
        </v-skeleton-loader>
    </v-col>
</v-row>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'CollectionSwitch',

  props: {
    value: {
      type: Object,
      default() {
        return {
          currentCol: undefined,
          currentGroup: undefined,
        };
      },
    },
  },
  data() {
    return {
      col_loading: true,
      group_loading: true,
      filter: '',
      collections: [],
      groups: [],
    };
  },
  mounted() {
    this.$http.get(`${this.$api_url}/collections`).then((response) => {
      this.collections = response.data;
      this.col_loading = false;
      if (this.$route.query.col !== undefined) {
        this.value.currentCol = parseInt(this.$route.query.col, 10);
      }
      if (this.value.currentCol !== undefined) {
        return this.$http.get(`${this.$api_url}/groups/${this.value.currentCol}`).then((res) => {
          this.groups = res.data;
          if (this.$route.query.grp !== undefined) {
            this.value.currentGroup = parseInt(this.$route.query.grp, 10);
          }
          this.group_loading = false;
        });
      }
      this.$http.get(`${this.$api_url}/groups`).then((res2) => {
        this.groups = res2.data;
        if (this.$route.query.grp !== undefined) {
          this.value.currentGroup = parseInt(this.$route.query.grp, 10);
        }
        this.group_loading = false;
      });
      return '';
    });
  },
  methods: {
    changeCollection() {
      this.group_loading = true;
      const a = _.clone(this.$route.query);
      a.col = this.value.currentCol;
      a.grp = undefined;
      this.$router.push({ path: this.$route.path, query: a });
      if (this.value.currentCol !== undefined) {
        this.value.currentGroup = undefined;
        this.$http.get(`${this.$api_url}/groups/${this.value.currentCol}`).then((response) => {
          this.groups = response.data;
          this.group_loading = false;
        });
      } else {
        this.$http.get(`${this.$api_url}/groups`).then((response) => {
          this.groups = response.data;
          this.group_loading = false;
        });
      }
      this.$emit('input', {
        currentCol: this.value.currentCol,
        currentGroup: this.value.currentgroup,
      });
    },
    changeGroup(data) {
      this.currentGroup = data;
      const a = _.clone(this.$route.query);
      a.col = this.value.currentCol;
      a.grp = this.value.currentGroup;
      this.$router.push({ path: this.$route.path, query: a });
    },
  },
};
</script>
