<template>
<v-card :loading="globalLoading">

    <v-card-title>
       <v-btn @click="$emit('cancel')" icon>
<v-icon>mdi-close</v-icon>
        </v-btn>


        &nbsp;{{currentMaster.name}}

        <v-spacer></v-spacer>
          <v-chip v-if="currentMaster.watermark" class="red white--text" left>Watermark</v-chip>

        <v-chip v-else class="green white--text" left>Master</v-chip>

    </v-card-title>

    <v-card-text>
      <v-row>
        <v-tabs @change="changed" v-model="currentTab"
        show-arrows centered background-color="secondary">
            <v-tab>
                <v-icon left>mdi-play</v-icon>
                Lecture
            </v-tab>
            <v-tab :v-if="!currentMaster.ready">
                <v-icon left>mdi-camera</v-icon>
                Miniature
            </v-tab>
            <v-tab v-if="this.$store.state.userData.admin">
                <v-icon left>mdi-pencil</v-icon>
                Editer
            </v-tab>
            <v-tab>
                <v-icon left>mdi-share</v-icon>
                partager
            </v-tab>
            <v-tab-item>
                <v-card flat>
                    <v-card-text>
                        <v-row>

                                <v-card flat="" v-if="currentMaster.ready" ref="playerDiv">


                                    <v-card-text>
                                      <v-row>
                                        <v-col>
                                           <hls-player ref="player"
                                    :playing="play"
                                    :thumb="imgSrc" :autoplay="false"
                                    :source="currentMaster.hls"></hls-player>
                                        </v-col>
                                        <v-col>
                                        <h4>Commentaires : </h4>
                                        {{currentMaster.comment}}
                                        <h4>Ajouté par : </h4>
                                        {{currentMaster.user}}
                                        <h4>Date d'ajout : </h4>
                                        {{currentMaster.date}}
                                        <br>
                                        <v-btn v-if="!currentMaster.watermark"
                                         text color="success" @click="watermark">
                                         <v-icon>mdi-copyright</v-icon>Watermarker</v-btn>
                                        <v-btn
                                         text color="error" @click="download">
                                         <v-icon>mdi-download</v-icon>Télécharger</v-btn>
                                        </v-col>
                                        </v-row>
                                        <div v-if="downloading">
                                          Téléchargement sécurisé du master
                                          <v-progress-linear :value="progress"></v-progress-linear>
                                        </div>
                                    </v-card-text>

                                </v-card>

                            <v-card flat v-else>
                                <master-progress :master="currentMaster"></master-progress>
                            </v-card>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-tab-item>
            <v-tab-item>
                <v-card flat :disabled="!currentMaster.ready">
                    <v-card-text>

                        <thumb-changer @cancel="currentTab=0"
                        :master="currentMaster"></thumb-changer>

                    </v-card-text>
                </v-card>
            </v-tab-item>
            <v-tab-item v-if="this.$store.state.userData.admin">
                <v-card flat >
                    <v-card-text>
                        <edit-master-widget @reload="deleted"
                         @cancel="currentTab=0" :master="currentMaster"></edit-master-widget>

                    </v-card-text>
                </v-card>
            </v-tab-item>
            <v-tab-item>
                <v-card flat>
                    <v-card-text>
                        <share-master-widget @cancel="currentTab=0"
                        :master="currentMaster"></share-master-widget>

                    </v-card-text>
                </v-card>
            </v-tab-item>
        </v-tabs>
      </v-row>
    </v-card-text>

</v-card>
</template>

<script>
import HlsPlayer from './player/HlsPlayer.vue';
import ShareMasterWidget from './ShareMasterWidget.vue';
import EditMasterWidget from './EditMasterWidget.vue';
import ThumbChanger from './ThmubChanger.vue';
import MasterProgress from './MasterProgress.vue';

export default {
  name: 'MasterDetails',
  components: {
    HlsPlayer,
    ShareMasterWidget,
    ThumbChanger,
    EditMasterWidget,
    MasterProgress,
  },
  computed: {
    imgSrc() {
      return `${this.$api_url}/master_thumb/${this.currentMaster.id}/${this.$store.state.token}.jpg?${this.currentMaster.key}`;
    },
  },
  data() {
    return {
      play: false,
      currentTab: 0,
      globalLoading: false,
      downloading: false,
      progress: 0,
    };
  },
  watch: {
    imgSrc(newValue) {
      if (this.$refs.player !== undefined) {
        this.$refs.player.player.configure({ poster: newValue });
      }
    },
  },
  methods: {
    download() {
      this.downloading = true;
      this.$http({
        onDownloadProgress: (progressEvent) => {
          this.progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        },
        url: `${this.$api_url}/download_master`,
        data: {
          id: this.currentMaster.id,
        },
        method: 'POST',
        responseType: 'blob',
      })
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'master.mp4');
          document.body.appendChild(link);
          link.click();
          this.downloading = false;
        })
        .catch((e) => {
          console.log(e);
          this.$dialog.notify.error('une erreur est survenue', {
            position: 'top-centered',
            timeout: 10000,
          });
        });
    },
    deleted() {
      this.$emit('reload');
      this.$emit('cancel');
    },
    changed() {
      this.play = false;
      if (this.$refs.player !== undefined) {
        this.$refs.player.player.pause();
      }
    },
    watermark() {
      this.$confirm("Voulez-vous vraiment crééer une version wartermark ? Cela entrainera la création d'un nouveau master.").then((res) => {
        if (res === true) {
          this.globalLoading = true;
          this.$http.get(`${this.$api_url}/watermark/${this.currentMaster.id}`).then(() => {
            this.$emit('reload');
            this.$emit('cancel');
            this.globalLoading = false;
          });
        }
      });
    },
  },
  props: {
    currentMaster: {
      type: Object,
      default: undefined,
    },

  },
};
</script>
