<template>
<v-flex xs12>
<v-card class="mx-auto" max-width="500" :loading="card_loading" v-if="!granted">

                <v-card-title> Accès aux clips
                </v-card-title>


            <v-card-text class="text--primary">
               Pour accéder au partage rentrez le mot de passe
                <v-text-field type="password" @keypress="error_message=''"
                 @keypress.native.enter="requestAccess" :error-messages="error_message"
                 label="Mot de passe" v-model="password"></v-text-field>

            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn v-if="!granted"
                @click="requestAccess" color="accent" text>
                    Valider
                </v-btn>

            </v-card-actions>
</v-card>
<v-card v-else>

                <v-card-title> Préparation de votre panier -  {{name}}
                </v-card-title>


            <v-card-text class="text--primary">
              <v-progress-circular v-if="loading" indeterminate></v-progress-circular>
              <h4 v-else>Téléchargement...</h4>
            </v-card-text>

</v-card>
</v-flex>
</template>

<script>
export default {
  name: 'SelShare',
  methods: {
    requestAccess() {
      this.card_loading = true;
      this.$http.post(`${this.$api_url}/validate_selection`, { uuid: this.$route.params.share_id, passw: this.password }).then((res) => {
        this.card_loading = false;
        if (res.data.granted === false) {
          this.error_message = 'Mauvais mot de passe';
          return;
        }
        this.key = res.data.granted;
        this.name = res.data.name;
        this.granted = true;
        this.$http({
          url: `${this.$api_url}/request_selection`,
          data: { key: res.data.granted },
          method: 'POST',
          responseType: 'blob',
        }).then((res2) => {
          this.loading = false;
          const url = window.URL.createObjectURL(new Blob([res2.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${this.name}.zip`);
          document.body.appendChild(link);
          link.click();
        });
      });
    },
  },
  data() {
    return {
      card_loading: false,
      granted: false,
      error_message: '',
      password: '',
      loading: true,
      key: '',
      name: '',
    };
  },
};
</script>

<style>

</style>
