
<template>
    <v-container class="fill">
<v-row>
<login-form></login-form>
</v-row>

</v-container>
</template>

<script>
import LoginForm from '../components/LoginForm.vue';

export default {
  name: 'LoginPage',
  components: {
    LoginForm,
  },
};
</script>

<style lang="scss" scoped>

</style>
