<template>
<v-flex xs12>

    <v-skeleton-loader class="mx-auto" max-width="400" type="card" :loading="loading">
        <v-card class="mx-auto" max-width="500" :loading="card_loading" v-if="found">
            <v-img class="white--text align-end" height="200px" :src="this.master_data.image">
                <v-card-title> Accès au Master
                </v-card-title>

            </v-img>

            <v-card-text v-if="!granted && master_data.ready" class="text--primary">
                {{master_data.name}}
                <v-text-field type="password" @keypress="error_message=''"
                 @keypress.native.enter="requestAccess" :error-messages="error_message"
                 label="Mot de passe" v-model="password"></v-text-field>
            </v-card-text>
             <v-card-text v-if="!master_data.ready" class="text--primary warning">
                {{master_data.name}}<div>
                <v-icon>mdi-alert-circle</v-icon>
              Le master n'est pas encore prêt. Réssayez dans quelques instants...</div>
            </v-card-text>
            <v-card-text v-if="granted && !action">
                <v-row>

                    <v-col cols="12">
                        {{master_data.name}}</v-col>
                </v-row>

                <v-row>
                    <v-col cols="12">
                        <v-btn @click="seeMaster" color="accent" width="100%">
                            <v-icon left>mdi-eye</v-icon>
                            Voir en ligne
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-btn color="primary" @click="download" :href="locked_data.dl"
                        :enabled="locked_data.dl!=''"
                         target="_blank" v-if="locked_data.dl !== null" width="100%">
                            <v-icon left>mdi-download</v-icon>Télécharger
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-text class="justify-center" v-if="granted && downloading && !seeing">
                <v-row class="justify-center">

                    <v-progress-circular :size="50" color="primary" indeterminate>

                    </v-progress-circular>

                </v-row>
                <v-row class="justify-center"><span> Téléchargement en cours</span></v-row>
            </v-card-text>
             <v-card-text class="justify-center" v-if="granted && seeing &&!downloading">
               <v-row class="justify-center">
              <v-btn icon @click="back"><v-icon >mdi-arrow-left</v-icon>
              </v-btn><v-spacer></v-spacer>{{master_data.name}}
              </v-row>
              <v-row>
                <v-divider></v-divider>
              </v-row>
              <v-row ref="player_parent">
                <hls-player :source="locked_data.hls"></hls-player>
              </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn v-if="!granted && master_data.ready"
                @click="requestAccess" color="accent" text>
                    Valider
                </v-btn>

            </v-card-actions>
        </v-card>
         <v-alert v-else
      border="right"
      colored-border
      type="error"
      elevation="2"
      max-width="500"
      class="mx-auto"
    >
 Master non trouvé. Le partage n'existe plus. Veuillez contacter le créateur du lien.    </v-alert>

    </v-skeleton-loader>
</v-flex>
</template>

<script>
import HlsPlayer from '../components/player/HlsPlayer.vue';

export default {
  name: 'MasterDeliver',
  components: {
    HlsPlayer,
  },
  methods: {
    back() {
      this.seeing = false;
      this.action = false;
    },
    download() {
      this.downloading = true;
      this.action = true;
    },
    seeMaster() {
      this.seeing = true;
      this.action = true;
    },
    requestAccess() {
      this.card_loading = true;
      this.$http.post(`${this.$api_url}/validate_share`, {
        uuid: this.master_data.uuid,
        password: this.password,
      }).then((res) => {
        if (res.data.ok === false) {
          this.error_message = 'Mauvais mot de passe';
          this.granted = false;
        } else {
          this.granted = true;
          this.locked_data.hls = res.data.ok.hls;
          this.locked_data.dl = res.data.ok.dl;
        }
      });

      this.card_loading = false;
    },
  },
  data() {
    return {
      found: true,
      loading: true,
      seeing: false,
      action: false,
      card_loading: false,
      granted: false,
      error_message: '',
      password: '',
      downloading: false,
      master_data: {},
      locked_data: {
        dl: false,
        hls: undefined,
      },

    };
  },
  mounted() {
    this.$http.get(`${this.$base_url}/api/share/${this.$route.params.share_id}`).then((result) => {
      this.master_data = result.data;
      this.loading = false;
    }).catch(() => {
      this.loading = false;
      this.found = false;
    });
  },
};
</script>
